import {useEffect} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {useContent, useLang} from "../../hooks";
import {ContentLanguageSchema} from "./schema";
import {BackButton} from "../../components/BackButton/BackButton";

export function ContentLanguageEditPage() {
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {language, isLoading, error, edit} = useLanguage("content", langId);
  const {content, isLoading: isContentLoading, contentError} = useContent()

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError || contentError) {
    notifyError(error)
    navigate(-1);
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(ContentLanguageSchema(t)),
  });

  useEffect(() => {
    if (language) {
      setValue("title", language.name);
      setValue("description", language.description);
      setValue("language_id", language.language_id);
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      await edit({data, content_id: resourceId});
      notifySuccess({
        title: `Language  updated successfully`,
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message,
      });
    }
  };

  return (
      <Layout>
        {(isLoading || isLangLoading || isContentLoading) && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/langs/content/${resourceId}`}>{content?.title ?? resourceId} Languages</Link>
              </li>
              <Breadcrumb.Item active>Edit Language</Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("contents_contents")}/>
          </div>
        </header>
        <section id="#content-language-create">
          <h3>Edit Language ({content?.name ?? resourceId})</h3>
          <Form
              onSubmit={handleSubmit(handleEdit)}
              className="mt-4 d-flex  flex-column gap-3"
          >

            <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>

            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">{t("contents_create_name")}</Form.Label>
              <Form.Control
                  {...register("title")}
                  placeholder={t("contents_create_name_plhd")}
                  type="text"
              />
              {errors.title?.message && (
                  <Form.Text className="text-danger">
                    {errors.title.message}
                  </Form.Text>
              )}
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label className="mark-required">{t("contents_create_Description")}</Form.Label>
              <Form.Control
                  {...register("description")}
                  placeholder="Content description"
                  as="textarea"
                  rows={3}
              />
              {errors.description?.message && (
                  <Form.Text className="text-danger">
                    {errors.description.message}
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex gap-2 justify-content-end">
              <Button className="px-4" type="submit">
                Save
              </Button>
              <Link to={-1} className="px-4 btn btn-danger">
                Cancel
              </Link>
            </div>
          </Form>
        </section>
      </Layout>
  );
}
