import {api, parseAPIError} from "./api";

export function parseSettingsFromAPIResponse(data) {
  try {
    const settings = {};
    for (const s of data) {
      settings[s.slug] = {
        id: s.id,
        value: s.value
      };
    }
    return settings;
  } catch (err) {
    throw new Error("Invalid Settings data");
  }
}

export async function getGeneralSettings() {
  const r = await api.get(`/general_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateGeneralSettings(input) {
  try {
    const r = await api.post("/general_setting/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getSocialSettings() {
  const r = await api.get(`/social_media/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateSocialSettings(input) {
  try {
    const r = await api.post("/social_media/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getCommonSettings() {
  const r = await api.get(`/common_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateCommonSettings(input) {
  try {
    const r = await api.post("/common_setting/update", input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getHeaderSettings() {
  const r = await api.get(`/header_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

function processHeaderSettingsInput(input) {
  return Object.fromEntries(
    Object.entries(input)
      .filter(([_, v]) => v != null && v != "")
      .map(([k, v]) => {
        if (k === 'logo' && v instanceof File) {
          // If the key is 'logo' and the value is a Buffer, return it as is
          return [k, v];
        } else if (v === Object(v)) {
          // If the value is an object, recursively process it
          return [k, processHeaderSettingsInput(v)];
        } else {
          // Otherwise, return the key-value pair as is
          return [k, v];
        }
      })
  );
}

export async function updateHeaderSettings(input) {
  try {
    const r = await api.post("/header_setting/update", processHeaderSettingsInput(input));
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getFooterSettings(columnName) {
  const r = await api.get(`/footer_${columnName}_setting/show`);
  return parseSettingsFromAPIResponse(r.data.data);
}

export async function updateFooterSettings(columnName, input) {
  try {
    const r = await api.post(`/footer_${columnName}_setting/update`, input);
    return parseSettingsFromAPIResponse(r.data.data);
  } catch (err) {
    throw parseAPIError(err);
  }
}