import React, { useState, useEffect } from "react";
import "./css/style.css";
import { FaUserAlt, FaKey } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Form, Toast } from "react-bootstrap";
import http from "../../http";
import Cookies from "universal-cookie";
import update_logo from "../../images/UZEY.png";

const ResetPassword = () => {
  const cookies = new Cookies();
  const { register, handleSubmit } = useForm();
  const [getLoginData, setLoginData] = useState("");
  const navigate = useNavigate();
  const [getError, setError] = useState({});
  const [getAuthError, setAuthError] = useState(false);
  const [getEmail, setEmail] = useState(false);

  const { token } = useParams();

  const onSubmit = (data, e) => {
    setError({});
    setAuthError(false);

    data = { ...getLoginData };
    http
      .post("/reset-password/" + token, data)
      .then((res) => {
        if (res.status == 200) {
          setEmail(true);
        }
        return res;
      })
      .then((res) => {
        if (res.status == 200) {
          setTimeout(() => {
            setEmail(false);
            navigate("/login");
          }, 2000);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message == "Validation Error.") {
          let errs = err.response.data.data;
          let keys = Object.keys(errs);
          let errors = {};
          keys.map((key) => (errors[key] = errs[key][0]));
          console.info(errors);
          setError(errors);
        } else {
          setAuthError(true);
        }
      });
  };

  const onError = (errors, e) => console.log(errors, e);

  const handleChange = (e) => {
    setError("");
    setAuthError(false);
    let loginData = { ...getLoginData };
    loginData[e.target.name] = e.target.value;
    setLoginData(loginData);
  };

  return (
    <>
      {getEmail == true ? (
        <Toast
          bg="success"
          style={{
            position: "fixed",
            top: "30px",
            right: "0",
            zIndex: "111111",
          }}
        >
          <Toast.Body>Succesfully Sent Email</Toast.Body>
        </Toast>
      ) : null}

      <div className="limiter">
        <div
          className="container-login100"
          style={{ backgroundImage: "url(/Group-1241.png)" }}
        >
          <div className="wrap-login100 pt-4">
            {/* <span className="login100-form-title pt-4 pb-5">Reward Management</span> */}
            <img src={update_logo} className="mx-auto d-block img-fluid pt-4 pb-5"/>

            {getAuthError ? (
              <div className="pb-4">
                <p className="authError">The Token is mismatch</p>
              </div>
            ) : null}
            <Form
              className="login100-form validate-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group
                className={`wrap-input100 ${
                  getError.password ? "alert-validate" : null
                } validate-input mb-3`}
                data-validate={getError?.password}
              >
                <Form.Control
                  id="input100"
                  type="password"
                  name="password"
                  placeholder=" Enter the Password"
                  onChange={(e) => handleChange(e)}
                />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <FaKey />
                </span>
              </Form.Group>

              <Form.Group
                className={`wrap-input100 ${
                  getError.confirm_password ? "alert-validate" : null
                } validate-input mb-3`}
                data-validate={getError?.confirm_password}
              >
                <Form.Control
                  id="input100"
                  type="password"
                  name="confirm_password"
                  placeholder=" Enter the Confirm Password"
                  onChange={(e) => handleChange(e)}
                />
                <span className="focus-input100" />
                <span className="symbol-input100">
                  <FaKey />
                </span>
              </Form.Group>

              <div className="container-login100-form-btn pt-3">
                <Button
                  id="login100-form-btn"
                  variant="success"
                  type="submit"
                >
                  SUBMIT
                </Button>
                <div className="text-center p-3">
                  <Link to="/login" style={{ color: "black" }}>
                    Back to Login Page
                  </Link>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
