import {useState} from "react";
import {ButtonToolbar, Modal} from "rsuite";
import {Button} from "react-bootstrap";
import MediaManager from "./MediaManager";
import {useLang} from "../../hooks";

function MediaModal({
                      selectedMedia,
                      setSelectedMedia,
                      checkValue,
                      setCheckValue,
                      allMediaId,
                      handleMediaAddClick = () => {
                      }
                    }) {
  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const handleMediaModalOpen = () => setMediaModalOpen(true);
  const handleMediaModalClose = () => {
    setMediaModalOpen(false);
    handleMediaAddClick();
  };
  const {t} = useLang();

  return (
    <>
      <ButtonToolbar>
        <Button onClick={() => handleMediaModalOpen("full")} variant="outline-primary">
          + {t("album_input_addMedia")}
        </Button>
      </ButtonToolbar>
      <Modal size={"lg"} open={mediaModalOpen} onClose={handleMediaModalClose} backdrop={"static"}>
        <Modal.Header>
          <Modal.Title>{t("album_input_addMedia")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MediaManager selectedMedia={selectedMedia} setSelectedMedia={setSelectedMedia}
                        checkValue={checkValue} setCheckValue={setCheckValue} allMediaId={allMediaId}/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleMediaModalClose}>
            {t("save_btn")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MediaModal;
