import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLang} from "../../hooks";
import {EventLanguageInputSchema} from "./schema";
import {useEvent} from "../../hooks/useEvent";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {TextEdit} from "../../components";
import moment from "moment";

export function EventLanguageCreatePage() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {event, isLoading: isContentLoading, error} = useEvent(resourceId);

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    });
    navigate(-1);
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(EventLanguageInputSchema(t))
  });

  useEffect(() => {
    if (event) {
      setValue("title", event.title ?? "");
      setValue("description", event.description ?? "");
      setValue("organised_by", event.organised_by ?? "");
      setValue("resource_person", event.resource_person ?? "");
      setValue("location", event.location ?? "");
      setValue("start_date", moment(event.start_date).format("MMMM, DD, YYYY (hh:mm:ss a)") ?? "");
      setValue("end_date", moment(event.start_date).format("MMMM, DD, YYYY (hh:mm:ss a)") ?? "");
    }
  }, [event]);

  console.log(errors);

  const create = async (data) => {
    try {
      setIsLoading(true);
      data.event_id = resourceId;
      const l = await createLanguage("event", data);
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created"
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isLoading || isContentLoading || isLangLoading) && <CircleLoader />}
      <section id="#event-lang-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/events/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/event/${resourceId}`}>{event?.title ?? resourceId} Languages</Link>
            </li>
            <Breadcrumb.Item active>create language</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Create Language ({event?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />
          <Form.Group controlId="title">
            <Form.Label className="mark-required">{t("events_input_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("events_input_title_p")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group>
            <Form.Label className="mark-required">{t("events_input_startEndDateTime")}</Form.Label>
            <div className="d-flex gap-2">
              <div className="flex-grow-1">
                <Form.Control
                  {...register("start_date")}
                  type="text"
                />
                {errors.start_date?.message && (
                  <Form.Text className="text-danger">
                    {errors.start_date.message}
                  </Form.Text>
                )}
              </div>
              <div className="flex-grow-1">
                <Form.Control
                  {...register("end_date")}
                  type="text"
                />
                {errors.end_date?.message && (
                  <Form.Text className="text-danger">
                    {errors.end_date.message}
                  </Form.Text>
                )}
              </div>
            </div>
          </Form.Group>
          <Form.Group controlId="image">
            <Form.Label>{t("events_input_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <ImageInput
                  {...(event?.image && {defaultPreviewImage: getUrlFromStoragePath(event.image)})}
                  errorMessage={errors.image?.message}
                  onChange={field.onChange}
                  onError={() => {
                    setError("image", {message: "Invalid image file!"});
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">{t("events_input_description")}</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({field}) => <TextEdit defaultValue={event?.description} {...field} />}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>


          <div className="form-row">
            <Form.Group as={Col} md={5} controlId="organised_by">
              <Form.Label className="mark-required">{t("events_input_organisedBy")}</Form.Label>
              <Form.Control
                {...register("organised_by")}
                placeholder={t("events_input_organisedBy_p")}
                type="text"
              />
              {errors.organised_by?.message && (
                <Form.Text className="text-danger">
                  {errors.organised_by.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} md={5} controlId="resource_person">
              <Form.Label className="mark-required">{t("events_input_resourcePerson")}</Form.Label>
              <Form.Control
                {...register("resource_person")}
                placeholder={t("events_input_resourcePerson_p")}
                type="text"
              />
              {errors.resource_person?.message && (
                <Form.Text className="text-danger">
                  {errors.resource_person.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>

          <Form.Group controlId="location">
            <Form.Label className="mark-required">{t("events_input_location")}</Form.Label>
            <Form.Control
              {...register("location")}
              placeholder={t("events_input_location_p")}
              as="textarea"
              rows={3}
            />
            {errors.location?.message && (
              <Form.Text className="text-danger">
                {errors.location.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              Create
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
