import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {TaxonomyInputSchema} from "./schema";
import {createTaxonomy} from "../../services/taxonomy";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

export function TaxonomyCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(TaxonomyInputSchema(t)),
  });

  const create = async (data) => {
    try {
      setIsLoading(true);
      const t = await createTaxonomy(data)
      notifySuccess({
        title: "Taxonomy created successfully",
        message: `Taxonomy "${t.name}" created!`,
      });
      navigate("/taxonomies/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create taxonomy",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Taxonomy creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {isLoading && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/taxonomies/manage`}>{t("taxonomies_manage_taxonomies")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("taxonomies_create_createNewTaxonomy")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("taxonomies_manage_taxonomies")}/>
        </div>
      </header>
      <section id="#taxonomy-create">
        <h2>{t("taxonomies_create_createNewTaxonomy")}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(create)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={2}>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <ImageInput
                  errorMessage={errors.image?.message}
                  onChange={field.onChange}
                  onError={() => {
                    setError("image", {message: "Invalid image file!"})
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("taxonomies_input_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              placeholder={t("taxonomies_input_name_p")}
              type="text"
            />
            {errors.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <Form.Group controlId="description">
          <Form.Label className="mark-required">{t("taxonomies_input_description")}</Form.Label>
          <Form.Control
            {...register("description")}
            placeholder={t("taxonomies_input_description_p")}
            as="textarea"
            rows={3}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end">
          <Button className="px-4" type="submit">
            {t("taxonomies_create_create")}
          </Button>
        </div>
      </Form>
    </Layout>
  );
}
