import * as yup from "yup";
import {
    DefaultDescriptionValidation,
    defaultImageValidation,
    DefaultNameValidation,
    languageSchema,
} from "../../configs/constants";

export const TaxonomyInputSchema = (t) => yup.object({
    name: DefaultNameValidation().required().label(t("taxonomies_manage_taxonomies")),
    description: DefaultDescriptionValidation().required().label(t("taxonomies_input_description")),
    image: defaultImageValidation,
});

export const TaxonomyLanguageSchema = () => languageSchema.shape({
    name: DefaultNameValidation().required(),
    description: DefaultDescriptionValidation().required(),
    image: defaultImageValidation,
})
