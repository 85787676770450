import {useLang} from "../../../hooks";
import {useNotification} from "../../../hooks/useNotification";
import {useGeneralSettings} from "../hooks/useGeneralSettings";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {GeneralSettingsSchema, generalSettingsSchema} from "../schema";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {InputValidationError} from "../../../libs/error";
import {InputGroup, Placeholder, TagInput} from "rsuite";
import {Button, Form} from "react-bootstrap";
import {BsGlobe} from "react-icons/bs";

export function GeneralSettings({onLanguageEdit}) {
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();
  const {settings, error, isLoading, save} = useGeneralSettings();


  const {
    register,
    formState: {errors},
    setValue,
    handleSubmit,
    setError,
    control
  } = useForm({
    resolver: yupResolver(GeneralSettingsSchema(t))
  });

  useEffect(() => {
    if (settings) {
      setValue("site_title", settings.site_title?.value);
      setValue("tag_line", settings.tag_line?.value);
      setValue("site_address", settings.site_address?.value);
      setValue("email_address", settings.email_address?.value.split(",") ?? []);
      setValue("roboket_chat_base_url", settings.roboket_chat_base_url?.value ?? '');
      setValue("roboket_chat_website_token", settings.roboket_chat_website_token?.value ?? '');
    }
  }, [settings]);

  const navigate = useNavigate();
  if (error) {
    notifyError(error);
    navigate("/dashbaord");
  }

  const handleUpdate = async (data) => {
    try {
      if(data.email_address.length > 0) {
        data.email_address = data.email_address.join(',')
      }
      await save(data);
      notifySuccess({
        title:"Success",
        message:"General Settings Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update general settings!",
        message: err.message
      });
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return <section id="general-settings" className="my-4">
    <h2 className="mb-3">{t("settings_general_generalSettings")}</h2>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <Form.Group controlId="site_title">
          <Form.Label className="mark-required">{t("settings_general_siteTitle")}</Form.Label>
          <InputGroup>
            <Form.Control
              {...register("site_title")}
              placeholder={t("settings_general_siteTitle_plhd")}
            />
            <Button onClick={() => {
              onLanguageEdit(settings.site_title?.id);
            }}>
              <BsGlobe />
            </Button>
          </InputGroup>
          {errors.site_title?.message && (
            <Form.Text className="text-danger">
              {errors.site_title.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="tag_line">
          <Form.Label className="mark-required">{t("settings_general_tagLine")}</Form.Label>
          <div className="position-relative">
            <Form.Control
              {...register("tag_line")}
              placeholder={t("settings_general_tagLine_plhd")}
              as="textarea"
              rows={3}
            />
            <Button className="floating-language-btn" onClick={() => {
              onLanguageEdit(settings.tag_line?.id);
            }}>
              <BsGlobe />
            </Button>
          </div>
          {errors.tag_line?.message && (
            <Form.Text className="text-danger">
              {errors.tag_line.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="site_address">
          <Form.Label className="mark-required">{t("settings_general_siteAddress")}</Form.Label>
          <Form.Control
            {...register("site_address")}
            placeholder={t("settings_general_siteAddress_plhd")}
            type="url"
          />
          {errors.site_address?.message && (
            <Form.Text className="text-danger">
              {errors.site_address.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="email_address">
          <Form.Label className="mark-required">{t("settings_header_clientEmail")}</Form.Label>
          <Controller
            control={control}
            name="email_address"
            render={({field}) => (
              <TagInput
                {...field}
                validat
                placeholder={t("settings_header_clientEmail_phld")}
                style={{borderColor: "#ced4da"}}
                className="d-block"
                trigger={['Space','Enter']}
                onChange={(value) => {
                  const validEmails = value.filter(email => isValidEmail(email));
                  field.onChange(validEmails);
                }}
              />
            )}
          />
          {(errors.email_address?.message || Array.isArray(errors.email_address)) && (
            <Form.Text className="text-danger">
              {errors?.email_address?.message ??
                errors.email_address.find(Boolean).message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="roboket_chat_base_url">
          <Form.Label className="mark-required">{t("settings_general_roboketChatBaseUrl")}</Form.Label>
          <Form.Control
            {...register("roboket_chat_base_url")}
            placeholder={t("settings_general_roboketChatBaseUrl_p")}
            type="url"
          />
          {errors.roboket_chat_base_url?.message && (
            <Form.Text className="text-danger">
              {errors.roboket_chat_base_url.message}
            </Form.Text>
          )}
        </Form.Group>
        <Form.Group controlId="roboket_chat_website_token">
          <Form.Label className="mark-required">{t("settings_general_roboketChatWebsiteToken")}</Form.Label>
          <Form.Control
            {...register("roboket_chat_website_token")}
            placeholder={t("settings_general_roboketChatWebsiteToken_p")}
            type="text"
          />
          {errors.roboket_chat_website_token?.message && (
            <Form.Text className="text-danger">
              {errors.roboket_chat_website_token.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("settings_general_save")}
          </Button>
        </div>
      </Form>}

  </section>;
}