import * as yup from "yup";

/*****************/
/* Common fields */
/*****************/

export const DefaultNameValidation = () => yup.string().max(200).label("Name");

export const DefaultDescriptionValidation = () => yup
  .string()
  .max(5000)
  .label("Description");

export const DefaultLinkValidation = () => yup.string().url().label("Link");

/*************/
/*  M e t a  */
/*************/

export const MetaTitle = () => yup.string().max(800).label("Meta Title");
export const MetaDescription = () => yup
  .string()
  .max(800)
  .label("Meta Description");
export const MetaTagValidation = () => yup
  .array()
  .of(yup.string().max(60))
  .max(13)
  .label("Meta Tag");

export const SeoSchema = () => yup.object({
  meta_title: MetaTitle(),
  meta_tag: MetaTagValidation(),
  meta_description: MetaDescription()
});

/*********************/
/*  L a n g u a g e  */
/*********************/

export const languageSchema = yup.object({
  language_id: yup.number().required().label("Language")
}).required();

/***************/
/*  F i l e s  */
/***************/

export const defaultImageValidation = yup.mixed().nullable();
export const defaultVideoValidation = yup.mixed().nullable();


