import {api, parseAPIError} from "./api";

export function processMediaInput(input) {
  switch (Number(input.media_category_id)) {
    case 1:
      input.original_url = input.image;
      break;
    case 2:
      input.original_url = input.video;
      break;
    case 3:
      input.original_url = input.document;
      break;
    case 4:
      input.original_url = input.external_url;
      break;
    default:
      break;
  }
  return input;
}

export async function getCategories() {
  try {
    const r = await api.get(`/media/categories`);
    return r.data.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getMedia(id) {
  try {
    const r = await api.get(`/media/get/` + id);
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function createMedia(input) {
  try {
    const r = await api.post("/media/store", processMediaInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function updateMedia(input, id) {
  try {
    const r = await api.post(`/media/update/` + id, processMediaInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function removeMedia(id) {
  const r = await api.delete(`/media/delete/${id}`);
  return r.data.data;
}

export async function getPaginatedMedia(options) {
  const r = await api.get("/media/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}