import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {useContent, useLang, useTermList} from "../../hooks";
import {ContentInputSchema} from "./schema.js";
import {VideoInput} from "../../components/MediaInputs/videoInput";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {BackButton} from "../../components/BackButton/BackButton";

export function ContentEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {content, editContent, isLoading, error} = useContent(id);
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(9, {limit: 1000, page: 1});

  if (termsError) {
    notifyError(termsError);
    navigate("/contents/manage");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(ContentInputSchema(t)),
  });

  const name = watch("name");

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/contents/manage"), 500);
  }

  useEffect(() => {
    if (content && termList.terms.length > 0) {
      setValue("title", content.title ?? "");
      setValue("description", content.description ?? "");
      setValue("link", content.link ?? "");
      setValue("terms_id", String(content.terms_id) ?? "");
    }
  }, [content, termList]);

  const handleEdit = async (data) => {
    try {
      await editContent(data);
      notifySuccess({
        title: `Content "${content.name}" updated successfully`,
      });
      navigate("/contents/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update content!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isTermsLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("contents_edit_edit")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("contents_contents")}/>
        </div>
      </header>
      <section id="#content-edit">
        <h2>{t("contents_edit_edit")} {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={4} controlId="terms_id">
            <Form.Label className="mark-required">{t("contents_edit_term")}</Form.Label>
            <Form.Control {...register("terms_id")} as="select" custom>
              <option value={-1}>None</option>
              {termList.terms.map((trm) => (
                <option value={trm.id}>{trm.name}</option>
              ))}
            </Form.Control>
            {errors.terms_id?.message && (
              <Form.Text className="text-danger">
                {errors.terms_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("contents_edit_name")}</Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("contents_edit_name_plhd")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="description">
          <Form.Label>{t("contents_edit_Description")}</Form.Label>
          <Form.Control
            {...register("description")}
            placeholder={t("contents_edit_Description_plhd")}
            as="textarea"
            rows={3}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} md={12} lg={6} className="mb-4">
            <Form.Label>{t("contents_create_image")}</Form.Label>
            <div className="h-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <ImageInput
                    {...(content?.image && {previousImage: getUrlFromStoragePath(content.image)})}
                    errorMessage={errors.image?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("image", {message: "Invalid image file!"})
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
          <Form.Group as={Col} md={12} lg={6} className="mb-4" controlId="video">
            <Form.Label>{t("contents_create_video")}</Form.Label>
            <div className="h-100">
              <Controller
                control={control}
                name="video"
                render={({field}) => (
                  <VideoInput
                    {...(content?.video && {previousVideo: getUrlFromStoragePath(content.video)})}
                    errorMessage={errors.video?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("video", {message: "Invalid video file!"})
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
        </div>
        <Form.Group controlId="Link">
          <Form.Label>{t("contents_create_link")}</Form.Label>
          <Form.Control
            {...register("link")}
            placeholder={t("contents_create_link_plhd")}
            type="text"
          />
          {errors.link?.message && (
            <Form.Text className="text-danger">
              {errors.link.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("contents_edit_save")}
          </Button>
          <Link className="btn btn-danger" to="/contents/manage">
            {t("contents_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
