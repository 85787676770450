import {useEffect, useState} from "react";
import {getComponent, updateComponent} from "../services/component";

export default function useComponent(id) {
  const [component, setComponent] = useState();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchComponent = async () => {
    try {
      setIsLoading(true);
      setComponent(await getComponent(id));
    } catch (err) {
      setError({
        title: "Failed to fetch component!",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editComponent(data) {
    try {
      setIsLoading(true);
      setComponent(await updateComponent(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchComponent();
  }, []);

  return {component, isLoading, editComponent, error};
}
