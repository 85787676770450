import {useCallback, useEffect, useState} from "react";
import {getEvent, updateEvent} from "../services/event";

export function useEvent(id) {
  const [event, setEvent] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true);
      setEvent(await getEvent(id));
    } catch (err) {
      setError({
        title: "Failed to fetch event!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  async function edit(data) {
    try {
      setIsLoading(true);
      setEvent(await updateEvent(id, data));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {event, isLoading, edit, error};
}
