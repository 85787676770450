import Layout from "../../components/layouts/layout";
import {Link, useNavigate, useParams} from "react-router-dom";
import { useState, useEffect } from "react";
import * as yup from "yup";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import { TagInput, useToaster, Notification } from "rsuite";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import { useError } from "../../hooks/useError";
import {useLang, usePrivateAPI} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const menuEditSchema = yup
  .object({
    name: yup
      .string()
      .required("Name is required")
      .max(31, "Name must not greater than 31 character"),
    description: yup
      .string()
      .required("Description is required")
      .max(255, "Description must not greater than 255 character"),
    meta_title: yup
      .string()
      .required("Meta Title is required")
      .max(60, "Meta title must greater than 60 character"),
    meta_tag: yup
      .array()
      .of(yup.string().max(31, "Each tag must not exceed 31 character"))
      .max(5, "Maximum of 5 tag can be added")
      .required("Tags is required"),
    meta_description: yup
      .string()
      .required("Meta description is required")
      .max(255, "Meta description must not greater than 255 character"),
  })
  .required();

function useMenu(id) {
  const [menu, setMenu] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { pushError } = useError();
  const { http } = usePrivateAPI();

  const fetchMenu = async () => {
    setIsLoading(true);
    try {
      const res = await http.get(`/terms/get/${id}`);
      setMenu(res.data.data);
    } catch (err) {
      pushError({
        title: "Failed to edit menu",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const updateMenu = async (data) => {
    data.taxonomy_id = 8;
    data.meta_tag = data.meta_tag.join(",");
    setIsLoading(true);
    try {
      const res = await http.post(`/terms/update/${id}`, data);
      setMenu(res.data.data);
    } catch (err) {
      pushError({
        title: "Failed to edit menu",
        message: err.message,
      });
      return false;
    } finally {
      setIsLoading(false);
    }
    return true;
  };

  useEffect(() => {
    fetchMenu();
  }, []);

  return { menu, isLoading, updateMenu };
}

export function EditMenuPage() {
  const { id } = useParams();
  const { menu, isLoading, updateMenu } = useMenu(id);
  const toaster = useToaster();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(menuEditSchema),
  });
  const {t} = useLang();

  useEffect(() => {
    if (menu) {
      setValue("name", menu.name);
      setValue("meta_title", menu.meta_title);
      setValue("description", menu.description);
      setValue("meta_description", menu.meta_description);
      setValue("meta_tag", menu.meta_tag?.split(",") ?? "");
    }
  }, [menu]);

  if (errors) {
    console.log(errors);
  }

  const handleMenuEdit = async (data) => {
    if (await updateMenu(data)) {
      toaster.push(
        <Notification header="Menu updated successfully!" type="success" />,
        { placement: "bottomCenter" }
      );
      navigate("/menus/manage");
    }
  };

  return (
      <Layout>
        {isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/menus/manage`}>{t("menu_manageMenu")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("menu_edit_editMenu")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("menu_manageMenu")}/>
          </div>
        </header>
        <h2>{t("menu_edit_editMenu")}</h2>
        <section id="createMenuForm" className="pr-md-5 mr-md-5">
          <Form
              onSubmit={handleSubmit(handleMenuEdit)}
              className="mt-4 d-flex flex-column gap-3"
          >
            <div className="form-row">
              <Form.Group as={Col} controlId="name">
                <Form.Label className="mark-required">{t("menu_edit_name")}</Form.Label>
                <Form.Control
                    {...register("name")}
                    placeholder={t("menu_edit_name_plhd")}
                    type="text"
                />
                {errors.name?.message && (
                    <Form.Text className="text-danger">
                      {errors.name.message}
                    </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="metaTitle">
                <Form.Label className="mark-required">{t("menu_edit_metaTitle")}</Form.Label>
                <Form.Control
                    {...register("meta_title")}
                    placeholder={t("menu_edit_metaTitle_plhd")}
                    type="text"
                />
                {errors.meta_title?.message && (
                    <Form.Text className="text-danger">
                      {errors.meta_title.message}
                    </Form.Text>
                )}
              </Form.Group>
            </div>
            <Form.Group controlId="metaTags">
              <Form.Label className="mark-required">{t("menu_edit_metaTags")}</Form.Label>
              <Controller
                  control={control}
                  name="meta_tag"
                  render={({field}) => (
                      <TagInput
                          {...field}
                          placeholder={t("menu_edit_metaTags_plhd")}
                          style={{borderColor: "#ced4da"}}
                          className="d-block"
                      />
                  )}
              />
              {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                  <Form.Text className="text-danger">
                    {errors?.meta_tag?.message ??
                        errors.meta_tag.find(Boolean).message}
                  </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label className="mark-required">{t("menu_edit_description")}</Form.Label>
              <Form.Control
                  {...register("description")}
                  placeholder={t("menu_edit_description_plhd")}
                  as="textarea"
                  rows={3}
              />
              {errors.description?.message && (
                  <Form.Text className="text-danger">
                    {errors.description.message}
                  </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="metaDescription">
              <Form.Label className="mark-required">{t("menu_edit_metaDescription")}</Form.Label>
              <Form.Control
                  {...register("meta_description")}
                  as="textarea"
                  rows={3}
                  placeholder={t("menu_edit_metaDescription_plhd")}
              />
              {errors.meta_description?.message && (
                  <Form.Text className="text-danger">
                    {errors.meta_description.message}
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                {t("menu_edit_update")}
              </Button>
            </div>
          </Form>
        </section>
      </Layout>
  );
}
