import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {useLang} from "../../hooks";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useEvent} from "../../hooks/useEvent";
import {TextEdit} from "../../components";
import {getUrlFromStoragePath} from "../../configs";
import {PostLanguageInputSchema} from "./schema";
import moment from "moment/moment";

export function PostLanguageEditPage() {
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {language, isLoading, error, edit} = useLanguage("post", langId);
  const {post, isLoading: isContentLoading, postError} = useEvent(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError || postError) {
    notifyError(error)
    navigate(-1);
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(PostLanguageInputSchema(t)),
  });

  useEffect(() => {
    if (language) {
      setValue("language_id", language.language_id)
      setValue("name", language.name ?? "");
      setValue("post_date", moment(language.post_date).format("MMMM, DD, YYYY (hh:mm:ss a)") ?? "")
      setValue("description", language.description ?? "");
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      await edit({...data, post_id: resourceId});
      notifySuccess({
        title: `Language  updated successfully`,
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading || isContentLoading) && <CircleLoader/>}
      <section id="#post-language-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/posts/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/post/${resourceId}`}>{post?.name} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit language/ {langId}</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Edit Language ({post?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <div className="form-row">
            <Form.Group controlId="name">
              <Form.Label className="mark-required">{t("posts_create_name")}</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder={t("posts_create_name_plhd")}
                type="text"
              />
              {errors.name?.message && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="post_date">
              <Form.Label className="mark-required">{t("posts_create_postDate")}</Form.Label>
              <Form.Control
                {...register("post_date")}
                type="text"
              />
              {errors.post_date?.message && (
                <Form.Text className="text-danger">
                  {errors.post_date.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Form.Group controlId="image">
            <Form.Label>{t("posts_create_image")}</Form.Label>
            <div className="w-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <ImageInput
                    {...(post?.image && {defaultPreviewImage: getUrlFromStoragePath(post.image)})}
                    errorMessage={errors.image?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("image", {message: "Invalid image file!"})
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">{t("posts_create_description")}</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({field}) => <TextEdit defaultValue={post?.description} {...field} />}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
