import {useCallback, useReducer, useRef, useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {FaSearch, FaEye } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {DateRangePicker, Input, InputGroup} from "rsuite";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, usePagination} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {useQuery} from "../../hooks/useQuery";
import filterReducers from "../../reducers/filterReducer";
import { Modal } from 'rsuite';
import moment from "moment";
import {getPaginatedClientForm} from "../../services/clientForm";

import GenericPdfDownloader from "../../components/GeneratePDF/GenericPdfDownloader";
import * as xlsx from 'xlsx';
import {exportToExcel} from "../../libs/utility/helper";
import {Controller, useForm} from "react-hook-form";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedPricing, removePricing} from "../pricing/pricing.service";

function ClientFormListPage() {
  const {t} = useLang();
  const [ selectedData, setSelectedData ] = useState(null)
  const [ fetchError, setFetchError ] = useState(null);
  const {notifyError} = useNotification();
  const [ open, setOpen ] = useState(false);
  const [ isExcelLoading, SetIsExcelLoading ] = useState(false);
  const {register, handleSubmit, control} = useForm();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem,
  } = usePaginatedList(getPaginatedClientForm, ()=>{})

  const handleOpen = (id) => {
    setSelectedData(list?.data.find(item => item.id === id));
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  if (error || fetchError) {
    notifyError(error, "/");
  }

  const fetchAllData = async () => {
    let allData = [];
    try {
      SetIsExcelLoading(true)
      allData = await getPaginatedClientForm({page: 1, limit: 1000000,})
    } catch (err) {
      setFetchError({
        title: "Something went wrong",
        message: err.message
      })
    }finally {
      SetIsExcelLoading(false)
    }
    return allData;
  }

  const handleExcelButtonClick = async () => {
    let fetchData = await fetchAllData();
    let excelData = fetchData?.data ?? null;
    const columnsToKeep = ['name', 'email', 'phone_number', 'business_name', 'title', 'current_website_url',
      'describe_your_business', 'your_business_goals', 'your_target_audience', 'your_virtual_assistant_to_perform',
      'specific_instructions', 'hours_per_week', 'virtual_assistant', 'virtual_assistant_details', 'find_your_business',
      'social_media', 'list_of_system', 'lead_generation', 'virtual_assistant_your_business'];
    const filteredData = excelData.map(obj => {
      const newObj = {};
      columnsToKeep.forEach(column => {
        if (obj.hasOwnProperty(column)) {
          let newColumnName = column.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          newObj[newColumnName] = obj[column];
        }
      });
      return newObj;
    });
    exportToExcel(filteredData, "Client Form");
  }
  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("clientForm_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("clientForm_manage_col_email"),
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: t("clientForm_manage_col_phone_number"),
      selector: (row) => row.phone_number,
      sortable: true
    },
    {
      name: t("clientForm_manage_col_created_at"),
      selector: (row) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true
    },
    {
      name: t("clientForm_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
              <Button onClick={() => handleOpen(m.id)}>
                <FaEye />
              </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        { isLoading || isExcelLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("clientForm_manage_clientForms")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("clientForm_manage_clientForms")}</h2>
          <div className="mt-3 mb-3">
            <Form
              onSubmit={handleSubmit((data) => {
                if (data.fromTo_date && data.fromTo_date.length === 2) {
                  data.fromDate = moment(data.fromTo_date[0]).format("yyyy-MM-DD");
                  data.toDate = moment(data.fromTo_date[1]).format("yyyy-MM-DD");
                }
                delete data.fromTo_date
                filterDispatch({
                  type: "reInitialize",
                  payload: data
                });
              })}
            >
              <div className="row">
                <Form.Group controlId="fromTo_date" className="col-md-4 col-lg-5">
                  <Form.Label className="fw-bold">{t("clientForm_manage_search_formToDate")}</Form.Label>
                  <Controller
                    control={control}
                    name="fromTo_date"
                    render={({field}) => (
                        <DateRangePicker block {...field} format="yyyy-MM-dd" />
                    )}
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-5 col-lg-5"
                  controlId="search"
                  onSubmit={handleSubmit((data) => {
                  })}
                >
                  <Form.Label className="fw-bold">{t("clientForm_manage_searchBy")}</Form.Label>
                  <Form.Control
                    {...register("term")}
                    placeholder={t("clientForm_manage_searchBy_p")}
                    custom
                  ></Form.Control>
                </Form.Group>
                <div className="col-md-3 col-lg-2">
                  <div className="h-100 d-flex justify-content-end align-items-center">
                    <Button className="px-5" type="submit">
                      {t("posts_manage_go")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={list?.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list?.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
          />
          <Button onClick={handleExcelButtonClick}>Export to Excel</Button>
        </section>
        <Modal size="lg" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid" id="myCleintPDF" style={{ fontFamily:'Arial'}}>
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("clientForm_manage_col_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_name")}</label>
                            <p className="form-control-static">{selectedData?.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_email")}</label>
                            <p className="form-control-static">{selectedData?.email}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_phone_number")}</label>
                            <p className="form-control-static">{selectedData?.phone_number}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("clientForm_manage_col_business_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_business_name")}</label>
                            <p className="form-control-static">{selectedData?.business_name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_title")}</label>
                            <p className="form-control-static">{selectedData?.title}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_current_website_url")}</label>
                            <p className="form-control-static">{selectedData?.current_website_url}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_describe_your_business")}</label>
                            <p className="form-control-static">{selectedData?.describe_your_business}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_your_business_goals")}</label>
                            <p className="form-control-static">{selectedData?.your_business_goals}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("clientForm_manage_col_VA_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_your_target_audience")}</label>
                            <p className="form-control-static">{selectedData?.your_target_audience}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_your_virtual_assistant_to_perform")}</label>
                            <p className="form-control-static">{selectedData?.your_virtual_assistant_to_perform}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_specific_instructions")}</label>
                            <p className="form-control-static">{selectedData?.specific_instructions}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_hours_per_week")}</label>
                            <p className="form-control-static">{selectedData?.hours_per_week}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_virtual_assistant")}</label>
                            <p className="form-control-static">{selectedData?.virtual_assistant}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_virtual_assistant_details")}</label>
                            <p className="form-control-static">{selectedData?.virtual_assistant_details}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12" style={{ breakInside:'avoid-page'}}>
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("clientForm_manage_col_additional_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_find_your_business")}</label>
                            <p className="form-control-static">{selectedData?.find_your_business}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_social_media")}</label>
                            <p className="form-control-static">{selectedData?.social_media}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_list_of_system")}</label>
                            <p className="form-control-static">{selectedData?.list_of_system}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_lead_generation")}</label>
                            <p className="form-control-static">{selectedData?.lead_generation}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("clientForm_manage_col_virtual_assistant_your_business")}</label>
                            <p className="form-control-static">{selectedData?.virtual_assistant_your_business}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <GenericPdfDownloader downloadFileName={"Client-Form-" + selectedData?.name } content={"myCleintPDF"}/>
            <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
}

export default ClientFormListPage;