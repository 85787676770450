import {useLang, useTermList} from "../../../hooks";
import {useHeaderSettings} from "../hooks/useHeaderSettings";
import {useNotification} from "../../../hooks/useNotification";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {HeaderSettingsSchema, headerSettingsSchema} from "../schema";
import {useEffect} from "react";
import {InputValidationError} from "../../../libs/error";
import {Button, Col, Form} from "react-bootstrap";
import {ImageInput} from "../../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../../configs";
import {InputGroup, Placeholder, SelectPicker} from "rsuite";
import {useNavigate} from "react-router-dom";
import {BsGlobe} from "react-icons/bs";

export function HeaderSettings({onLanguageEdit}) {
  const {t} = useLang();
  const {settings, error, isLoading, save} = useHeaderSettings();
  const {notifyError, notifySuccess} = useNotification();
  const {termList, istTerm: isTermsLoading, error: termError} =
    useTermList(8, {page: 1, limit: 10000});
  const navigate = useNavigate();

  const {
    register,
    formState: {errors},
    reset,
    handleSubmit,
    control,
    setError
  } = useForm({
    resolver: yupResolver(HeaderSettingsSchema(t))
  });

  if (error || termError) {
    notifyError(error ?? termError);
    navigate("/dashboard");
  }

  useEffect(() => {
    if (settings) {
      reset({
        site_address_header: settings.site_address_header?.value ?? "",
        site_email: settings.site_email?.value ?? "",
        site_phone: settings.site_phone?.value ?? "",
        alter_text: settings.alter_text?.value ?? "",
        button_title: settings.button_title?.value ?? "",
        button_link: settings.button_link?.value ?? "",
        main_menu_id: settings.main_menu_id?.value ?? "",
        mobile_menu_id: settings.mobile_menu_id?.value ?? ""
      });
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    try {
      await save(data);
      notifySuccess({
        title:"Success",
        message:"Header Settings Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return <section id="header-settings-form" className="my-4">
    <h2 className="mb-3">{t("settings_header")}</h2>
    {isLoading ?
      <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} md={4}>
            <Form.Label>{t("settings_site_logo")}</Form.Label>
            <div style={{height: "170px"}}>
              <Controller
                control={control}
                name="logo"
                render={({field}) => (
                  <ImageInput
                    {...(settings?.logo && {previousImage: getUrlFromStoragePath(settings?.logo?.value)})}
                    errorMessage={errors.logo?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("logo", {message: "Invalid logo file!"});
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>
          <Col>
            <Form.Group className="flex-grow-1" controlId="site_address_header">
              <Form.Label className="mark-required">{t("settings_header_site_address")}</Form.Label>
              <InputGroup>
                <Form.Control {...register("site_address_header")} placeholder={t("settings_header_site_address_phld")}
                              type="text" />
                <Button onClick={() => {
                  onLanguageEdit(settings.site_address_header?.id);
                }}>
                  <BsGlobe />
                </Button>
              </InputGroup>
              {errors.site_address_header?.message && (
                <Form.Text className="text-danger">{errors.site_address_header.message}</Form.Text>)}
            </Form.Group>

            <Form.Group className="flex-grow-1" controlId="site_email">
              <Form.Label className="mark-required">{t("settings_header_email")}</Form.Label>
              <Form.Control {...register("site_email")} placeholder={t("settings_header_email_phld")}
                            type="email" />
              {errors.site_email?.message && (
                <Form.Text className="text-danger">{errors.site_email.message}</Form.Text>)}
            </Form.Group>

            <Form.Group className="flex-grow-1" controlId="site_phone">
              <Form.Label className="mark-required">{t("settings_header_phone")}</Form.Label>
              <Form.Control {...register("site_phone")} placeholder={t("settings_header_phone_phld")}
                            type="phone" />
              {errors.site_phone?.message && (
                <Form.Text className="text-danger">{errors.site_phone.message}</Form.Text>)}
            </Form.Group>
          </Col>
        </div>
        <Form.Group className="flex-grow-1" controlId="alter_text">
          <Form.Label>{t("settings_header_logo_alt_text")}</Form.Label>
          <InputGroup>
            <Form.Control {...register("alter_text")} placeholder={t("settings_header_logo_alt_text_phld")}
                          type="text" />
            <Button onClick={() => {
              onLanguageEdit(settings.site_title?.id);
            }}>
              <BsGlobe />
            </Button>
          </InputGroup>
          {errors.alter_text?.message && (
            <Form.Text className="text-danger">{errors.alter_text.message}</Form.Text>)}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} controlId="main_menu_id">
            <Form.Label>
              {t("settings_header_main_menu")}
            </Form.Label>
            <div>
              <Controller render={({field}) => <SelectPicker
                onChange={field.onChange}
                value={field.value}
                style={{width: "100%"}}
                data={termList?.terms.map(t => ({value: String(t.id), label: t.name}))}
                placeholder={t("settings_header_main_placeholder")}
                loading={isTermsLoading}
              />
              } name="main_menu_id" control={control} />
            </div>
            {errors.main_menu_id?.message && (
              <Form.Text className="text-danger">
                {errors.main_menu_id.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col} controlId="mobile_menu_id">
            <Form.Label>
              {t("settings_header_mobile_menu")}
            </Form.Label>
            <div>
              <Controller render={({field}) => <SelectPicker
                onChange={field.onChange}
                value={field.value}
                style={{width: "100%"}}
                data={termList?.terms.map(t => ({value: String(t.id), label: t.name}))}
                placeholder={t("settings_header_menu_placeholder")}
                loading={isTermsLoading}
              />
              } name="mobile_menu_id" control={control} />
            </div>
            {errors.mobile_menu_id?.message && (
              <Form.Text className="text-danger">
                {errors.mobile_menu_id.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>
        <div className="form-row">
          <Form.Group as={Col} className="flex-grow-1" controlId="button_title">
            <Form.Label className="mark-required">{t("settings_header_button_txt")}</Form.Label>
            <InputGroup>
              <Form.Control {...register("button_title")} placeholder={t("settings_header_button_txt_phld")}
                            type="phone" />
              <Button onClick={() => {
                onLanguageEdit(settings.site_title?.id);
              }}>
                <BsGlobe />
              </Button>
            </InputGroup>
            {errors.button_title?.message && (
              <Form.Text className="text-danger">{errors.button_title.message}</Form.Text>)}
          </Form.Group>
          <Form.Group as={Col} className="flex-grow-1" controlId="button_link">
            <Form.Label className="mark-required">{t("settings_header_button_link")}</Form.Label>
            <Form.Control {...register("button_link")} placeholder={t("settings_header_button_link_phld")}
                          type="phone" />
            {errors.button_link?.message && (
              <Form.Text className="text-danger">{errors.button_link.message}</Form.Text>)}
          </Form.Group>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("common_settings_save")}
          </Button>
        </div>
      </Form>
    }

  </section>;
}