import {Checkbox, IconButton} from "rsuite";
import {Link} from "react-router-dom";
import {FaFile, FaLink, FaPencilAlt, FaTrashAlt} from "react-icons/fa";
import {Badge, Card, Image} from "react-bootstrap";
import {getUrlFromStoragePath} from "../../../configs";
import {defaultStyles, FileIcon} from "react-file-icon";
import {get_url_extension} from "../helpers/media";
import React from "react";
import '../media.css';

export default function MediaCard({isSelect, isEdit, isDelete, isDeleteServer, isSelected,
                                    onSingleChange, media, handleOpen = () => {}, handleRemove, handleServerRemove}) {
  let length = 20;
  const iconHelper = (media) => {
    if (media.media_category_id === 1) {
      return (
        <Image className="w-100 h-100 p-2"
               style={{objectFit: "contain"}}
               src={getUrlFromStoragePath(media.original_url)}
        />
      );
    } else if (media.media_category_id === 2) {
      let ext = get_url_extension(media.original_url);
      return (
        media.thumbnail_url ? (
          <Image className="w-100 h-100 p-2"
                 style={{objectFit: "cover"}}
                 src={getUrlFromStoragePath(media.thumbnail_url)}
          />) : (
          <div className="fileIcon">
            <FileIcon extension={ext} {...defaultStyles[ext]} />
          </div>
        )
      );
    } else if (media.media_category_id === 3) {
      let ext = get_url_extension(media.original_url);
      return (
        <div className="fileIcon">
          <FileIcon extension={ext} {...defaultStyles[ext]} />
        </div>
      );
    } else if (media.media_category_id === 4) return <FaLink className="cardIcon" />;
    else return <FaFile className="cardIcon" />;
  };

  const checkRender  = () => {
    if(isSelect) {
      if(onSingleChange !== null) {
        return <Checkbox value={media.id} className="chkBtnCard" onChange={onSingleChange} disabled={isSelected}/>
      } else {
        return <Checkbox value={media.id} className="chkBtnCard"  />
      }
    }
  }

  return (
    <div key={media.id} style={{marginBottom: "10px", minWidth: "240px", maxWidth: "240px"}}>
      <div style={{display: "flex", position: "relative"}}>
        { checkRender() }
        { isEdit && (<Link to={`/media/${media?.id}`}>
          <IconButton icon={<FaPencilAlt />} size="sm" className="shadow editBtnCard" />
        </Link>)}
        <div className="editBtnCard">
          {isDelete && <IconButton icon={<FaTrashAlt />} size="sm" className="shadow"
                                   onClick={() => handleRemove(media?.id)} />}
          {isDeleteServer && <IconButton icon={<FaTrashAlt />} size="sm" className="shadow"
                                         onClick={() => handleServerRemove(media?.id)} />}
        </div>
        <Card
          className={`w-100 shadow media-card ${isSelected ? 'checked-card' : ''} `}
          onClick={() => {
            handleOpen(media.id);
          }}
        >
          <div style={{height: "200px", backgroundColor: "#EEEDEB"}} className="d-flex">
            {iconHelper(media)}
          </div>
          <Card.Body style={{maxHeight: "150px"}}>
            <div className="d-flex justify-content-between">
              <div className="d-inline-block">
                <p className="text-wrap font-weight-bold">
                  {media.title?.length > length ? media.title.substring(0, length - 3) + "..." : media.title}
                </p>
              </div>
              <div>
                <Badge bg="secondary text-uppercase">{media.media_category?.name}</Badge>
              </div>
            </div>
            <div>
              <p className="text-uppercase font-weight-lighter">
                {media.media_category_id !== 4 ? get_url_extension(media.original_url) : "Link"}
              </p>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}