import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {createLanguage} from "../../services/language";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useContent, useLang} from "../../hooks";
import {ContentLanguageSchema} from "./schema";
import {BackButton} from "../../components/BackButton/BackButton";

export function ContentLanguageCreate() {
  const {resourceId} = useParams();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang()

  const {content, isLoading: isContentLoading, error} = useContent(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError) {
    notifyError({
      title: "Something went wrong!",
      message: error.message
    })
    navigate(-1)
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(ContentLanguageSchema(t)),
  });

  useEffect(() => {
    if (content) {
      setValue("title", content.title ?? "");
      setValue("description", content.description ?? "");
    }
  }, [content]);

  console.log(errors)


  const create = async (data) => {
    try {
      setIsLoading(true);
      data.master_crud_id = resourceId
      const l = await createLanguage("content", data)
      notifySuccess({
        title: "language created successfully!",
        message: "Language Created",
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to create language",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Language creation failed!",
      });
    } finally {
      setIsLoading(false);
    }
  };

  console.log(errors)

  return (
      <Layout>
        {(isLoading || isContentLoading || isLangLoading) && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to={`/langs/content/${resourceId}`}>{content?.title ?? resourceId} Languages</Link>
              </li>
              <Breadcrumb.Item active>Create Language</Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("contents_contents")}/>
          </div>
        </header>
        <section id="#content-lang-create">
          <h3>Create Language ({content?.name ?? resourceId})</h3>
          <Form
              onSubmit={handleSubmit(create)}
              className="mt-4 d-flex  flex-column gap-3"
          >

            <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
            <div className="form-row">
              <Form.Group as={Col} className="flex-grow-1" controlId="name">
                <Form.Label className="mark-required">{t("contents_create_name")}</Form.Label>
                <Form.Control
                    {...register("title")}
                    placeholder={t("contents_create_name_plhd")}
                    type="text"
                />
                {errors.title?.message && (
                    <Form.Text className="text-danger">
                      {errors.title.message}
                    </Form.Text>
                )}
              </Form.Group>
            </div>

            <Form.Group controlId="description">
              <Form.Label className="mark-required">{t("contents_create_Description")}</Form.Label>
              <Form.Control
                  {...register("description")}
                  placeholder="Content description"
                  as="textarea"
                  rows={3}
              />
              {errors.description?.message && (
                  <Form.Text className="text-danger">
                    {errors.description.message}
                  </Form.Text>
              )}
            </Form.Group>
            <div className="d-flex justify-content-end">
              <Button className="px-4" type="submit">
                Create
              </Button>
            </div>
          </Form>
        </section>
      </Layout>
  );
}
