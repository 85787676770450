import {api, parseAPIError} from "./api";

export function processComponentInput(input) {
  return input;
}

export async function getComponentCategories() {
  const r = await api.get("/component/categories");
  return r.data?.data?.map(
    item => ({label: item.name, value: item.id})
  ) ?? [];
}

export async function gettComponentIdentifierListByCategoryId(categoryId) {
  const r = await api.get(`/component/identifierList/${categoryId}`);
  return r.data?.data?.map(
    item => ({label: (item.name ? item.name : item.title), value: item.id})
  ) ?? [];
}


export async function createComponent(input) {
  try {
    const r = await api.post("/component/store", processComponentInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getComponent(id) {
  const r = await api.get(`component/get/${id}`);
  return r.data.data;
}

export async function updateComponent(id, input) {
  try {
    const r = await api.post(`component/update/${id}`, processComponentInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getPaginatedComponent(options) {
  const r = await api.get("/component/list", {
    params: options
  });
  if (r.data.data) {
    return r.data.data;
  }
  return {
    data: [],
    total: 0
  };
}

export async function removeComponent(id) {
  const r = await api.delete(`component/delete/${id}`);
  return r.data.data;
}

