import {useEffect, useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useNotification } from "../../hooks/useNotification";
import { InputValidationError } from "../../libs/error";

import { yupResolver } from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";

import {usePage} from "../../hooks/usePage";
import {pageEditSchema} from "./pageSchema";
import {TagInput} from "rsuite";
import {PageBuilder, processComponents} from "../../components/PageBuilder";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";


export function PageEditPage() {
    const {t} = useLang();
    const { id } = useParams();
    const {page,edit, isLoading, error } = usePage(id)
    const navigate = useNavigate();
    const { notifyError, notifySuccess } = useNotification();
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
        setError,
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(pageEditSchema),
        defaultValues: {
            contents: []
        }
    });

    const title = watch("title");

    if (error) {
        notifyError(error);
        setTimeout(() => navigate("/pages/manage"), 500);
    }

    useEffect(() => {
        if (page) {
            setValue("title", page.title ?? "");
            setValue("description",page.description ?? "");

            setValue("meta_title",page.meta_title ?? "");
            setValue("meta_tag",page.meta_tag?.split(",") ?? []);
            setValue("meta_description",page.meta_description ?? "");
            setValue("contents", processComponents(page.contents))
        }
    }, [page]);

    const handleEdit = async (data) => {
        try {
            await edit(data);
            notifySuccess({
                title: `Page "${page.title}" updated successfully`,
            });
            navigate("/pages/manage");
        } catch (err) {
            if (err instanceof InputValidationError) {
                err.setRHFError(setError);
                notifyError({
                    title: "Invalid input data!",
                    message: err.message,
                });
                return;
            }
            notifyError({
                title: "Failed to update page!",
                message: err.message,
            });
        }
    };

    return (
        <Layout>
            {(isLoading) && <CircleLoader/>}
            <header>
                <div className="d-flex justify-content-between align-items-center">
                    <Breadcrumb className="d-flex align-items-center">
                        <li className="breadcrumb-item">
                            <Link to={`/pages/manage`}> {t("pages_manage_pages")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            {title} {t("pages_edit_edit")}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("pages_manage_pages")}/>
                </div>
            </header>
            <section id="#content-edit">
                <h2>{t("pages_edit_edit")} {title}</h2>
            </section>
            <Form
                onSubmit={handleSubmit(handleEdit)}
                className="mt-4 d-flex  flex-column gap-3"
            >
                <Form.Group controlId="title">
                    <Form.Label className="mark-required">{t("pages_edit_title")}</Form.Label>
                    <Form.Control
                        {...register("title")}
                        placeholder={t("pages_edit_title_plhd")}
                        type="text"
                    />
                    {errors.title?.message && (
                        <Form.Text className="text-danger">
                            {errors.title.message}
                        </Form.Text>
                    )}
                </Form.Group>

                <Form.Group controlId="description">
                    <Form.Label className="mark-required">{t("pages_edit_description")}</Form.Label>
                    <Form.Control
                        {...register("description")}
                        placeholder={t("pages_edit_description_plhd")}
                        as="textarea"
                        rows={3}
                    />
                    {errors.description?.message && (
                        <Form.Text className="text-danger">
                            {errors.description.message}
                        </Form.Text>
                    )}
                </Form.Group>


                <Controller
                    control={control}
                    name="contents"
                    render={({field}) => (
                        <PageBuilder value={field.value} onChange={field.onChange}/>
                    )}
                />


                <article id="seo" className="mt-4">
                    <h3>{t("pages_edit_seo")}</h3>
                    <div className="mt-2 d-flex gap-2 flex-column">
                        <Form.Group controlId="metaTitle">
                            <Form.Label>{t("pages_edit_metaTitle")}</Form.Label>
                            <Form.Control
                                {...register("meta_title")}
                                placeholder={t("pages_edit_metaTitle_plhd")}
                                type="text"
                            />
                            {errors.meta_title?.message && (
                                <Form.Text className="text-danger">
                                    {errors.meta_title.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <Form.Group controlId="metaTags">
                            <Form.Label>{t("pages_edit_metaTags")}</Form.Label>
                            <Controller
                                control={control}
                                name="meta_tag"
                                render={({field}) => (
                                    <TagInput
                                        onChange={field.onChange}
                                        value={field.value}
                                        placeholder={t("pages_edit_metaTags_plhd")}
                                        style={{borderColor: "#ced4da"}}
                                        className="d-block"
                                    />
                                )}
                            />
                            {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                                <Form.Text className="text-danger">
                                    {errors?.meta_tag?.message ??
                                        errors.meta_tag.find(Boolean).message}
                                </Form.Text>
                            )}
                        </Form.Group>


                        <Form.Group controlId="metaDescription">
                            <Form.Label>{t("pages_edit_metaDescription")}</Form.Label>
                            <Form.Control
                                {...register("meta_description")}
                                as="textarea"
                                rows={3}
                                placeholder={t("pages_edit_metaDescription_plhd")}
                            />
                            {errors.meta_description?.message && (
                                <Form.Text className="text-danger">
                                    {errors.meta_description.message}
                                </Form.Text>
                            )}
                        </Form.Group>
                    </div>

                </article>

                <div className="d-flex justify-content-end gap-2">
                    <Button className="px-4" variant="success" type="submit">
                        {t("pages_edit_save")}
                    </Button>
                    <Link className="btn btn-danger" to="/contents/manage">
                        {t("pages_edit_cancel")}
                    </Link>
                </div>
            </Form>
        </Layout>
    );
}
