import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {BsLayoutTextSidebar, BsMenuButtonFill} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {MdFormatListBulleted, MdPostAdd, MdVpnKey} from "react-icons/md";
import {
  FaBars,
  FaBox,
  FaCalendar,
  FaCalendarPlus,
  FaCog,
  FaCubes,
  FaDollarSign,
  FaExclamationCircle,
  FaImages,
  FaKey,
  FaKeybase,
  FaPlus,
  FaPuzzlePiece,
  FaQuoteLeft,
  FaRegCalendarAlt,
  FaUserAlt,
  FaUserTie,
  FaClipboard,
  FaClipboardList
} from "react-icons/fa";
import {HiViewGridAdd} from "react-icons/hi";
import {IoExtensionPuzzleOutline} from "react-icons/io5";
import {LuTextSelect} from "react-icons/lu";
import {RiDashboardLine} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import avater from "../../images/avater.svg";
import logo from "../../images/UZEY.png";
import {fetchUsers} from "../../redux";
import DropdownManu from "./DropdownManu";
import "./sidebar.css";
import ReactFlagsSelect from "react-flags-select";
import {fetch_language} from "../../redux/language/listAction";
import {useLang} from "../../hooks/useLang";
import {logout} from "../../pages/Auth/auth";
import {useNotification} from "../../hooks/useNotification";


const Sidebar = ({children}) => {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const {User, Language} = useSelector((state) => state);
  const dispatch = useDispatch();
  const {notifyError} = useNotification();

  // console.table(cookies.get('userAuth'));
  const [show, setShow] = useState(false);
  const {t} = useLang();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const removeUser = async () => {
    await cookies.remove("userAuth", {path: "/"});
    // await cookies.remove("userPermissions", {path: '/'});
    await localStorage.removeItem("userPermissions");
    await navigate("/login");
  };


  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (err) {
      notifyError({title: "Failed to Logout", message: err.message});
    }
  };

  const token = cookies.get("userAuth")?.token;

  useEffect(() => {
    if (token) {
      dispatch(fetchUsers(cookies));
    }
  }, [token]);

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  useEffect(() => {
    if (window.innerWidth < 764) {
      setIsOpen(false);
    }
  }, [window.innerWidth]);


  const menuItem = [
    {
      id: "dashboard",
      path: "/dashboard",
      name: t("sidebar_dashboard"),
      icon: <RiDashboardLine />,
      permissions: []
    },
    {
      id: "user",
      path: "/user",
      name: t("sidebar_user_info"),
      icon: <FaUserAlt />,
      permissions: [Permission.UserList],
      submenue: [
        {
          path: "/user",
          name: t("sidebar_user_list"),
          icon: <FaUserTie />,
          permissions: [Permission.UserList]
        }
      ]
    },
    {
      id: "role",
      path: "/role",
      name: t("sidebar_acl"),
      icon: <MdVpnKey />,
      permissions: [Permission.RoleGet],
      submenue: [
        {
          path: "/role",
          name: t("sidebar_role_list"),
          icon: <FaKey />,
          permissions: [Permission.RoleGet]
        },
        {
          path: "/assign-user-role-list",
          name: t("sidebar_user_assign_to_role"),
          icon: <FaKeybase />,
          permissions: [Permission.RoleUserRoles]
        }
      ]
    },
    {
      id: "media",
      path: "/media/manage",
      name: t("sidebar_media_management"),
      icon: <FaImages />,
      permissions: [Permission.MediaList],
      submenue: [
        {
          path: "/media/manage",
          name: t("sidebar_media_list"),
          icon: <FaBars />,
          permissions: [Permission.MediaList]
        },
        {
          path: "/albums/manage",
          name: t("sidebar_album_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.GalleryAlbumNames]
        }
      ]
    },
    {
      id: "menus",
      path: "/menus/manage",
      name: t("sidebar_manage_menu"),
      icon: <BsMenuButtonFill />,
      permissions: [Permission.MenuIndex],
      submenue: [
        {
          path: "/menus/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.MenuIndex]
        },
        {
          path: "/menus/new",
          name: t("sidebar_new"),
          icon: <HiViewGridAdd />,
          permissions: [Permission.MenuStore]
        }
      ]
    },
    {
      id: "taxonomies",
      path: "/taxonomies/",
      name: t("sidebar_taxonomy_terms"),
      icon: <MdFormatListBulleted />,
      permissions: [Permission.TaxonomyList],
      submenue: [
        {
          path: "/taxonomies/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.TaxonomyList]
        },
        {
          path: "/taxonomies/new",
          name: t("sidebar_new"),
          icon: <CgPlayListAdd />,
          permissions: [Permission.TaxonomyStore]
        }
      ]
    },
    {
      id: "contents",
      path: "/contents/",
      name: t("sidebar_master_crud"),
      icon: <FaPuzzlePiece />,
      permissions: [Permission.ContentList],
      submenue: [
        {
          path: "/contents/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.ContentList]
        },
        {
          path: "/contents/new",
          name: t("sidebar_new"),
          icon: <IoExtensionPuzzleOutline />,
          permissions: [Permission.ContentCreate]
        }
      ]
    },
    {
      id: "posts",
      path: "/posts/",
      name: t("sidebar_post"),
      icon: <LuTextSelect />,
      permissions: [Permission.PostsList],
      submenue: [
        {
          path: "/posts/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.PostsList]
        },
        {
          path: "/posts/new",
          name: t("sidebar_new"),
          icon: <MdPostAdd />,
          permissions: [Permission.PostsStore]
        }
      ]
    },
    {
      id: "events",
      path: "/events/",
      name: t("sidebar_events"),
      icon: <FaRegCalendarAlt />,
      permissions: [Permission.EventList],
      submenue: [
        {
          path: "/events/manage",
          name: t("sidebar_manage"),
          icon: <FaCalendar />,
          permissions: [Permission.EventList]
        },
        {
          path: "/events/new",
          name: t("sidebar_new"),
          icon: <FaCalendarPlus />,
          permissions: [Permission.EventStore]
        }
      ]
    },
    {
      id: "products",
      path: "/products/",
      name: t("sidebar_products"),
      icon: <FaBox />,
      permissions: [Permission.ProductIndex],
      submenue: [
        {
          path: "/products/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.ProductIndex]
        },
        {
          path: "/products/new",
          name: t("sidebar_new"),
          icon: <IoExtensionPuzzleOutline />,
          permissions: [Permission.ProductStore]
        }
      ]
    },
    {
      id: "components",
      path: "/components/",
      name: t("sidebar_components"),
      icon: <FaCubes />,
      permissions: [Permission.ComponentList],
      submenue: [
        {
          path: "/components/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.ComponentList]
        },
        {
          path: "/components/new",
          name: t("sidebar_new"),
          icon: <IoExtensionPuzzleOutline />,
          permissions: [Permission.ComponentStore]
        }
      ]
    },
    {
      id: "pricing",
      path: "/pricing/",
      name: t("sidebar_pricing"),
      icon: <FaDollarSign  />,
      permissions: [Permission.ComponentList],
      submenue: [
        {
          path: "/pricing/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.ComponentList]
        },
        {
          path: "/pricing/new",
          name: t("sidebar_new"),
          icon: <IoExtensionPuzzleOutline />,
          permissions: [Permission.ComponentStore]
        }
      ]
    },
    {
      id: "testimonials",
      path: "/testimonials/",
      name: t("sidebar_testimonial"),
      icon: <FaQuoteLeft  />,
      permissions: [Permission.ComponentList],
      submenue: [
        {
          path: "/testimonials/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.ComponentList]
        },
        {
          path: "/testimonials/new",
          name: t("sidebar_new"),
          icon: <IoExtensionPuzzleOutline />,
          permissions: [Permission.ComponentStore]
        }
      ]
    },
    {
      id: "pages",
      path: "/pages/",
      name: t("sidebar_page"),
      icon: <LuTextSelect />,
      permissions: [Permission.PageList],
      submenue: [
        {
          path: "/pages/manage",
          name: t("sidebar_manage"),
          icon: <BsLayoutTextSidebar />,
          permissions: [Permission.PageList]
        },
        {
          path: "/pages/new",
          name: t("sidebar_new"),
          icon: <MdPostAdd />,
          permissions: [Permission.PageStore]
        }
      ]
    },
    {
      id: "forms",
      path: "/forms/",
      name: t("sidebar_forms"),
      icon: <FaClipboard  />,
      permissions: [Permission.ClientForm, Permission.ContactForm],
      submenue: [
        {
          path: "/client-forms",
          name: t("sidebar_forms_clientForm"),
          icon: <FaClipboardList  />,
          permissions: [Permission.ClientForm]
        },
        {
          path: "/contact-forms",
          name: t("sidebar_forms_contactForm"),
          icon: <FaClipboardList  />,
          permissions: [Permission.ContactForm]
        }
      ]
    },
    {
      id: "settings",
      path: "/settings",
      name: t("sidebar_settings"),
      icon: <FaCog />,
      permissions: [
        Permission.SocialMediaIndex,
        Permission.CommonSettingIndex,
        Permission.GeneralSettingsIndex
      ]
    }
  ];
  const image = process.env.REACT_APP_IMAGE_BASE_URL;

  // console.log(cookies.get('userAuth'))

  if (User.err) {
    removeUser();
  }

  if (Language.data.length == 0) {
    dispatch(fetch_language("US"));
  }

  const [select, setSelect] = useState(Language.data.length > 0 ? Language.data : "US");
  const onSelect = (code) => {
    console.log(code);
    setSelect(code);
    dispatch(fetch_language(code));
  };

  return (
    <>
      <div className="sidebar-container">
        <div
          style={{minWidth: isOpen ? "300px" : "50px"}}
          className={`sidebar ${isOpen ? "sidebar-open" : "sidebar-closed"}`}
        >
          <div className="top_section justify-content-between">
            <img
              src={logo}
              className="logo"
              alt="Logo"
            />
            {/* <div className="right_text">Reward</div> */}
            <div
              style={{
                marginLeft: isOpen ? "80px" : "0px",
                paddingLeft: isOpen ? "20px" : "0px",
                width: isOpen ? "43px" : "20px"
              }}
              className="bars pull-right"
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
          <div className="height">
            {menuItem.map((item, index) => (
              <ComponentGuard needsPermission={item.permissions}>
                {/* <NavLink to={item.path} key={index} className="link"  activeclassName="active">
                                <div className="icon" data-toggle="tooltip" data-placement="right" title={item.name}>{item.icon}</div>
                                <div className="link_text">{item.name}</div>
                            </NavLink> */}
                <DropdownManu item={item} key={index} isOpen={isOpen} />
              </ComponentGuard>
            ))}
          </div>
        </div>
        <div className="menu_wrapper">
          <div
            className="top_navbar"
            // style={{ marginLeft: isOpen ? "300px" : "50px" }}
          >
            {cookies.get["userAuth"]?.token !== null && (
              <>
                <span className="user_name fw-bold">
                  {cookies.get("userAuth")?.name}
                </span>
                <div className="user_wraper">
                  <span className="user">
                    <img
                      src={
                        User?.data?.image_url
                          ? image + "/" + User?.data?.image_url
                          : avater
                      }
                    />
                    <span className="down_arrow"></span>
                    {/* <img
                      src={
                        User?.data?.image_url
                          ? image + "/" + User?.data?.image_url
                          : avater
                      }
                    />
                     <span className="down_arrow"></span> */}
                    {/* <span className="user" ><img src={cookies.get('userAuth')?.image_url ? image+'/'+cookies.get('userAuth').image_url: avater}/> */}
                    <ul className="user_profile_list">
                      {/* <li className='up_item'><a href=''>Dashboard</a></li>  */}
                      <Link to="/user/profile">
                        <li className="up_item">{t("top_nav_my_profile")}</li>
                      </Link>
                      {/* <Link to='/user/password/change'><li className='up_item'>Password Change</li></Link> */}
                      <li
                        className="up_item"
                        type="button"
                        onClick={handleShow}
                      >
                        {t("top_nav_logout")}
                      </li>
                    </ul>
                  </span>


                  <div className="clock"
                       style={{marginTop: isOpen ? "30px" : "29px", right: isOpen ? "2.1%" : "1%"}}>
                    <div style={{"position": "absolute", "right": "75px", "top": "-9px"}}>
                      <ReactFlagsSelect
                        selected={select}
                        onSelect={onSelect}
                        countries={["US", "BD"]}
                        customLabels={{US: "EN-US", BD: "BD"}}
                        // showSelectedLabel={showSelectedLabel}
                        // selectedSize={selectedSize}
                        // showOptionLabel={showOptionLabel}
                        // optionsSize={optionsSize}
                        // placeholder={placeholder}
                        // searchable={searchable}
                        // searchPlaceholder={searchPlaceholder}
                        // alignOptionsToRight={alignOptionsToRight}
                        // fullWidth={fullWidth}
                        // disabled={disabled}
                      />

                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{marginTop: "85px"}}>
            <main
              className="mt-3"
              style={{paddingLeft: isOpen ? "300px" : "80px"}}
            >
              <div className="ms-4 children_wrapper">{children}</div>
            </main>
          </div>
        </div>
      </div>

      {/* <Button variant="primary" onClick={handleShow}>
            Launch demo modal
        </Button> */}

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize: "50px", margin: "auto"}}>
            <FaExclamationCircle />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign: "center", fontSize: "30px"}}>
          Do you want to logout?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Sidebar;
