import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-custom-build";

export function TextEdit({ defaultValue, onChange, onBlur, onFocus }) {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={defaultValue ?? ""}
      onChange={(e, editor) => {
        if (onChange) {
          onChange(editor.getData());
        }
      }}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
}
