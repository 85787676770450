import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import Layout from "../../components/layouts/layout";

import {Button, Form, Image} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {MediaEditSchema} from "./schema";
import {useMediaCategories} from "../../hooks/useMediaCategories";
import {useMedia} from "./Hooks/useMedia";
import {updateMedia} from "../../services/media";
import {InputValidationError} from "../../libs/error";
import {getUrlFromStoragePath} from "../../configs";
import ReactPlayer from "react-player";
import {FilePreview} from "../../components/MediaInputs/fileinput";
import {getFileNameFromUrl} from "../../libs/utility/helper";
import {SelectPicker} from "rsuite";

function MediaEditPage() {
  const {t} = useLang();
  const {id} = useParams();
  const {notifyError, notifySuccess} = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const {
    watch,
    register,
    handleSubmit,
    control,
    setError,
    setValue,
    formState: {errors}
  } = useForm({
    resolver: yupResolver(MediaEditSchema(t))
  });
  const {categories, isLoading: isCategoriesLoading, error: catErr} = useMediaCategories();
  const {media, isLoading: isMediaLoading, error: mediaErr} = useMedia(id);

  if (catErr || mediaErr) {
    notifyError(catErr ?? mediaErr, "/error");
  }
  if (errors) {
    console.log(errors);
  }

  const handleEdit = async (data) => {
    console.log("Called");
    try {
      setIsLoading(true);
      const media = await updateMedia(data, id);
      notifySuccess({
        title: "Media created successfully",
        message: `Media ${media.title} created!`
      }, "/media/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: err.message
      });
    } finally {
      setIsLoading(false);
    }
  };
  let mediaTypeSelected = watch("media_category_id");

  useEffect(() => {
    if (media && categories) {
      setValue("media_category_id", media.media_category_id ?? "");
      setValue("title", media.title ?? "");
      setValue("alt_text", media.alt_text ?? "");
      setValue("description", media.description ?? "");
    }
  }, [media, categories]);


  return (
    <Layout>
      {isLoading && <CircleLoader />}
      <div className="form_design">
        <h2 style={{color: "black", textDecoration: "underline"}}>{t("media_edit_updateMedia")}</h2>
        <Form onSubmit={handleSubmit(handleEdit)}>
          <Form.Group>
            <Form.Label className="form_label mark-required">{t("media_input_selectMediaType")}</Form.Label>
            <Controller name="media_category_id" control={control} render={({field: {onChange, value}}) => (
              <SelectPicker disabled onChange={onChange} value={value} data={categories?.map((item) => ({
                label: item.name,
                value: item.id
              }))} block searchable={false} loading={isCategoriesLoading} />
            )} />
            {errors?.media_category_id?.message &&
              <Form.Text className="text-danger">{errors.media_category_id.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label mark-required">{t("media_input_title")}</Form.Label>
            <Form.Control {...register("title")} type="text" placeholder={t("media_input_title_p")} />
            {errors?.title?.message && <Form.Text className="text-danger">{errors.title.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label">{t("media_input_alternativeText")}</Form.Label>
            <Form.Control {...register("alt_text")} type="text" placeholder={t("media_input_alternativeText_p")} />
            {errors?.alt_text?.message && <Form.Text className="text-danger">{errors.alt_text.message}</Form.Text>}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label className="form_label">{t("media_input_description")}</Form.Label>
            <Form.Control {...register("description")} as="textarea" rows={3}
                          placeholder={t("media_input_description_p")} />
            {errors?.description?.message &&
              <Form.Text className="text-danger">{errors.description.message}</Form.Text>}
          </Form.Group>
          {Number(mediaTypeSelected) === 1 && (
            <Form.Group controlId="image" className="mb-3">
              <Form.Label className="form_label mark-required">{t("media_input_selectImage")}</Form.Label>
              <Image thumbnail
                     src={Number(media?.media_category_id) === 1 && getUrlFromStoragePath(media?.original_url)}
                     alt={Number(media?.media_category_id) === 1 && getUrlFromStoragePath(media?.alt_text)}
                     width={"300"} className="d-block" />
            </Form.Group>
          )}
          {Number(mediaTypeSelected) === 2 && (
            <div className="row mb-3">
              <Form.Group className="col-md-6" controlId="video">
                <Form.Label className="form_label mark-required">{t("media_input_selectVideo")}</Form.Label>
                <ReactPlayer
                  url={Number(media?.media_category_id) === 2 && getUrlFromStoragePath(media?.original_url)}
                  controls width={"400"} height={"200"} />
              </Form.Group>
              <Form.Group className="col-md-6" controlId="thumbnail_url">
                <Form.Label className="form_label">{t("media_input_thumbnailImage")}</Form.Label>
                {
                  media?.thumbnail_url ? (
                    <Image thumbnail
                           src={getUrlFromStoragePath(media?.thumbnail_url)}
                           width={"300"} className="d-block" />
                  ) : (
                    <div>
                      No Image Available.
                    </div>
                  )
                }
              </Form.Group>
            </div>
          )}
          {Number(mediaTypeSelected) === 3 && (
            <Form.Group controlId="document" className="mb-3">
              <Form.Label className="form_label mark-required">{t("media_input_selectDocument")}</Form.Label>
              <div style={{width: "500px"}}>
                <FilePreview file={{name: getFileNameFromUrl(media?.original_url), size: null, lastModified: null}} />
              </div>
            </Form.Group>
          )}
          {Number(mediaTypeSelected) === 4 && (
            <Form.Group controlId="external_url" className="mb-3">
              <Form.Label className="form_label mark-required">{t("media_input_selectUrl")}</Form.Label>
              <Form.Control type="url" value={media?.original_url} placeholder={t("media_input_selectUrl_p")}
                            disabled />
            </Form.Group>
          )}
          <div className="mt-3 d-flex gap-2">
            <Button className="px-3" type="submit" variant="primary">
              {t("media_create_save")}
            </Button>
            <Link className="btn btn-danger px-3" to="/media/manage">
              {t("media_create_cancel")}
            </Link>
          </div>
        </Form>
      </div>
    </Layout>
  );
}

export default MediaEditPage;
