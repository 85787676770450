import {useFooterSettings} from "../hooks/useFooterSettings";
import {useLang, useTermList} from "../../../hooks";
import {useNotification} from "../../../hooks/useNotification";
import {useNavigate} from "react-router-dom";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {footerSettingsOne, FooterSettingsOneSchema} from "../schema";
import {useEffect} from "react";
import {InputValidationError} from "../../../libs/error";
import {Button, Col, Form} from "react-bootstrap";
import {ImageInput} from "../../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../../configs";
import {InputGroup, Placeholder, SelectPicker} from "rsuite";
import {BsGlobe} from "react-icons/bs";

export function FooterSettingsOne({onLanguageEdit}) {
  const {settings, error, isLoading, save} = useFooterSettings("one");
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();
  const {termList, isLoading: isTermsLoading, error: termError} =
    useTermList(8, {page: 1, limit: 10000});
  const navigate = useNavigate();

  const {
    register,
    formState: {errors},
    reset,
    handleSubmit,
    control,
    setError
  } = useForm({
    resolver: yupResolver(FooterSettingsOneSchema(t))
  });

  if (error || termError) {
    navigate(error ?? termError, "/error");
  }

  useEffect(() => {
    if (settings) {
      const {footer_logo_one, footer_menu_id_one, ...newInput} = settings;
      reset({
        footer_title_one: settings.footer_title_one?.value ?? "",
        footer_content_one: settings.footer_content_one?.value ?? "",
        footer_is_social_one: settings.footer_is_social_one?.value,
        footer_menu_id_one: settings.footer_menu_id_one ? String(settings.footer_menu_id_one.value) : ""
      });
    }
  }, [settings]);

  const handleUpdate = async (data) => {
    try {
      await save(data);
      notifySuccess({
        title:"Success",
        message:"First Column Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    }
  };

  return <section id="header-settings-form" className="my-4">
    <h3 className="mb-3">{t("settings_footer_one")}</h3>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <div className="form-row">
          <Form.Group as={Col} md={4}>
            <Form.Label>{t("settings_site_footer_logo")}</Form.Label>
            <div style={{height: "190px"}}>
              <Controller
                control={control}
                name="footer_logo_one"
                render={({field}) => (
                  <ImageInput
                    {...(settings?.footer_logo_one?.value && {previousImage: getUrlFromStoragePath(settings?.footer_logo_one?.value)})}
                    errorMessage={errors.footer_logo_one?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("footer_logo_one", {message: "Invalid logo file!"});
                    }}
                  />
                )}
              />
            </div>
          </Form.Group>

          <Col className="gap-2 d-flex flex-column">
            <div className="form-row">
              <Form.Group as={Col} className="flex-grow-1" controlId="footer_title_one">
                <Form.Label className="mark-required">{t("settings_footer_title")}</Form.Label>
                <InputGroup>
                  <Form.Control {...register("footer_title_one")} placeholder={t("settings_footer_title_phld")}
                                type="text" />

                  <Button onClick={() => {
                    onLanguageEdit(settings.footer_title_one?.id);
                  }}>
                    <BsGlobe />
                  </Button>
                </InputGroup>
                {errors.footer_title_one?.message && (
                  <Form.Text className="text-danger">{errors.footer_title_one.message}</Form.Text>)}
              </Form.Group>

              <Form.Group as={Col} className="flex-grow-1" controlId="footer_content_one">
                <Form.Label>{t("settings_footer_content")}</Form.Label>
                <InputGroup>
                  <Form.Control {...register("footer_content_one")} placeholder={t("settings_footer_content_phld")}
                                type="text" />
                  <Button onClick={() => {
                    onLanguageEdit(settings.footer_content_one?.id);
                  }}>
                    <BsGlobe />
                  </Button>
                </InputGroup>
                {errors.footer_content_one?.message && (
                  <Form.Text className="text-danger">{errors.footer_content_one.message}</Form.Text>)}
              </Form.Group>
            </div>
            <Form.Group controlId="footer_menu_id_one">
              <Form.Label>
                {t("settings_footer_menu")}
              </Form.Label>
              <div>
                <Controller render={({field}) => <SelectPicker
                  onChange={field.onChange}
                  value={field.value}
                  style={{width: "100%"}}
                  data={termList?.terms.map(t => ({value: String(t.id), label: t.name}))}
                  placeholder={t("settings_header_main_placeholder")}
                  loading={isTermsLoading}
                />
                } name="footer_menu_id_one" control={control} />
              </div>
              {errors.footer_menu_id_one?.message && (
                <Form.Text className="text-danger">
                  {errors.footer_menu_id_one.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="footer_is_social_one">
              <Form.Label>{t("settings_footer_is_social")}</Form.Label>
              <Form.Control
                {...register("footer_is_social_one")}
                as="select"
              >
                <option value="1">{t("settings_yes")}</option>
                <option value="0">{t("settings_no")}</option>
              </Form.Control>
              {errors.footer_is_social_one?.message && (
                <Form.Text className="text-danger">
                  {errors.footer_is_social_one.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </div>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("common_settings_save")}
          </Button>
        </div>
      </Form>}
  </section>;
}