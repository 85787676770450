import React,{useState,useEffect } from 'react';
import './role.css';
import { Link,useNavigate } from 'react-router-dom';
import {Button, Modal, Toast, Badge, Breadcrumb} from "react-bootstrap";
import http from "../../http"
// import {GrView} from "react-icons/gr";
// import {AiTwotoneEdit} from "react-icons/ai";
// import {MdDelete} from "react-icons/md";
import DataTable from 'react-data-table-component';
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import ComponentGuard from '../../helpers/ComponentGuard';
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const RoleUserList = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState('');
  const [users, setUsers] = useState([]);
  const [getDeleteUser, setDeleteUser] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUsers, setFilterUsers] = useState([]);

  const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [pages, setPages] = useState();
    const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
    const {t} = useLang();


    const navigate = useNavigate();
    useEffect(()=> {
        if(!loginCheck()){
            navigate("/login");
        }
    });

    // const handleClose = () => setShow(false);
    // const handleShow = (id) => {
    //     setShow(true);
    //     setId(id)  
    // };

    // const deleteDepartment = (id) => 
    // {
        // http.get('/department/delete/'+id,{
        //   headers:{
        //     "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
        //    } 
    //     }).then((res)=>{
    //         setDeleteDepartment(true);
    //         setTimeout(()=>{
    //             setDeleteDepartment(false)}
    //         ,2000) 
                
    //     })
    //     handleClose();
    // };

   
    useEffect(()=>{
        fetchAllUsers(getCurrentPage);
    },[getDeleteUser]);

    useEffect(()=>{
        let query = search.length > 0 ? `users/search/${search}` : `users`;

        http.get(`/role/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }}
             ).then((response)=>{
            setTotalRows(response.data.data.total);
            setFilterUsers(response.data.data.data);
        })
        
        // })
        // fetchAllMenus2()
    },[search]);


    // +++++++++++++++++++
    const fetchAllUsers = async page => {
        setCurrentPage(page)
		setLoading(true);
        setPages(page)
		const response = await http.get(`/role/users?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }});
        setFilterUsers(response.data.data.data)
		setUsers(response.data.data.data);
		setTotalRows(response.data.data.total);
		setLoading(false);
	};

	const handlePageChange = async (page) => {
        setCurrentPage(page)
        let query = search.length > 0 ? `users/search/${search}` : `users`;

		setLoading(true);
		const response = await http.get(`/role/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             }});
        
		setUsers(response.data.data.data);
        setFilterUsers(response.data.data.data)
		setPerPage(perPage);
		setLoading(false);
	};

	useEffect(() => {
		fetchAllUsers(1); // fetch page 1 of users
		
	}, []);

    // +++++++++++++++++++


    console.log(users)


    const columns =[
        {
            name: '#',
            cell: (row, index) => ((getCurrentPage-1)*perPage)+(index + 1),  //RDT provides index by default
            width: "50px"
        },
        {
            name: t("acl_userRoleList_col_userName"),
            selector: row => row.name,
            sortable:true
        },
        {
            name: t("acl_userRoleList_col_roleName"),
            selector: row => (row.roles?.length > 0) ? row.roles?.map(ele => <Badge style={{marginRight:"10px"}} bg="warning" text="dark">{ele.name}</Badge>):<Badge bg="warning" text="dark">N/A</Badge>,
            sortable:true,
            width: "750px"

        },
        // {
        //     name: "Phone Number",
        //     selector: row => (row.phone) ? row.phone:<Badge bg="warning" text="dark">N/A</Badge>,
        //     sortable:true
        // },
        // {
        //     name: "Language Parent",
        //     selector: row => (row.lang_parent)?row.lang_parent.title:<Badge bg="warning" text="dark">N/A</Badge>,
        //     sortable:true
        // },
        {
            name: t("acl_userRoleList_col_action"),
            cell: (row) => 
            (
                <>
                    <ComponentGuard needsPermission={['role.removeRoleFromUser']}>
                        <Link to={"delete-role/"+row.id}>
                            <Button className="action__btn" variant="info">
                                {t("acl_userRoleList_col_deleteRole")}
                            </Button>
                        </Link>
                    </ComponentGuard>
                </>
            ),            
        }
    ]
    return (
        <>
            <Layout>

                {/* {
                (getDeleteDepartment) ? 
                (        
                        <Toast bg='success' style={{position: 'fixed', top:'30px', right:'0'}}>
                            <Toast.Body >Department Deleted</Toast.Body>
                        </Toast>
                    )
                    :null
            } */}
                {/* <Link to="/role">
                <Button className="create_btn btn btn-primary" variant="primary">Back</Button>
            </Link> */}
                <header>
                    <div className="d-flex justify-content-between">
                        <Breadcrumb>
                            <li className="breadcrumb-item">
                                <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
                            </li>
                            <Breadcrumb.Item active>
                                {t("acl_userRoleList_userRoleList")}
                            </Breadcrumb.Item>
                        </Breadcrumb>
                        <BackButton path={-1} text={t("acl_roleList_roleList")}/>
                    </div>
                </header>

                <DataTable
                    title={t("acl_userRoleList_userRoleList")}
                    columns={columns}
                    data={filterUsers}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationComponentOptions={{noRowsPerPage: true}}
                    paginationTotalRows={totalRows}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    fixedHeader
                    fixedHeaderScrollHeight="800px"
                    highlightOnHover
                    subHeader
                    subHeaderComponent={
                        <input
                            type="text"
                            placeholder={t("acl_userRoleList_searchHere")}
                            className="search_btn form-control"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    }
                    subHeaderAlign="right"
                />


                {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Link to="/department">
                        <Button className="action__btn" variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="action__btn" onClick={() => deleteDepartment(id)} variant="danger">
                            Delete
                        </Button>
                    </Link>
                </Modal.Footer>
            </Modal> */}

            </Layout>
        </>
    );
}

export default RoleUserList;