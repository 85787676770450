import {useCallback, useEffect, useState} from "react";
import {getProduct, getProductAttrbutes, getProductCategories, updateProduct} from "../services/product";
import {organizeDataIntoTree} from "../helpers";

export function useProduct(id) {
  const [product, setProduct] = useState()
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchProduct = async () => {
    try {
      setIsLoading(true);
      let myData = await getProduct(id)
      // @TODO: @Joy Explain me this
      let attributes = myData.product_pivot_terms.map(term => String(term.attribute_id));
      myData.attributes = attributes
      setProduct(myData);
    } catch (err) {
      setError({
        title: "Failed to fetch product!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editProduct(data) {
    try {
      setIsLoading(true);
      const myData = await updateProduct(id, data)
      let attributes = myData.product_pivot_terms.map(term => String(term.attribute_id));
      myData.attributes = attributes
      setProduct(myData);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProduct();
  }, []);


  return {product, isLoading, editProduct, error}
}

export function useProductCategories() {
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true)
      setCategories(organizeDataIntoTree(await getProductCategories()))
    } catch (err) {
      setError({
        title: 'Failed to fetch Categories!',
        message: err.message,
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {categories, isLoading, error}
}

export function useProductAttributes() {
  const [attributes, setAttributes] = useState([]);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const fetch = useCallback(async () => {
    try {
      setIsLoading(true)
      setAttributes(organizeDataIntoTree(await getProductAttrbutes()))
    } catch (err) {
      setError({
        title: 'Failed to fetch Attributes!',
        message: err.message,
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {attributes, isLoading, error}
}
