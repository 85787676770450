import * as yup from "yup";
import {defaultImageValidation, languageSchema} from "../../configs/constants";

export const MenuItemSchema = (t) => yup
  .object({
    icon: yup.string().label(t("menu_content_icon")),
    title: yup.string().required().label(t("menu_content_title")),
    link: yup.string().label(t("menu_content_link")),
    description: yup.string().max(255).label("menu_content_Description"),
    page_id: yup.string().label(t("menu_content_page")).nullable(),
    parent_id: yup.string().label(t("menu_content_parent")).nullable(),
    image: defaultImageValidation.label(t("menu_content_image"))
  })
  .required();

export const MenuItemLanguageSchema = (t) => languageSchema.shape({
  title: yup.string().required().label(t("menu_content_title")),
  description: yup.string().max(255).label("menu_content_Description")
});
