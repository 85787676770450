import {api, parseAPIError} from "./api";
import {arrayToObject} from "../helpers";
import {processSeoInput} from "./seo";

export function processProductInput({attribute_id, ...input}) {
  if(attribute_id?.length > 0 ) {
    return {...processSeoInput(input), ...arrayToObject("attribute_id", attribute_id)}
  } else {
    return {...processSeoInput(input)}
  }
}

export async function createProduct(input) {
  try {
    const r = await api.post("/product/store", processProductInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getProduct(id) {
  const r = await api.get(`/product/get/${id}`);
  return r.data.data
}

export async function updateProduct(id, input) {
  try {
    const r = await api.post(`/product/update/${id}`, processProductInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedProduct(options) {
  const r = await api.get("product/show", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeProduct(id) {
  const r = await api.delete(`/product/destroy/${id}`);
  return r.data.data
}

export async function getProductAttrbutes() {
  const r = await api.get(`product/attributeList`)
  return r.data.data
}

export async function getProductCategories() {
  const r = await api.get(`product/categoryList`)
  return r.data.data
}
