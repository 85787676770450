import {useRef, useState} from "react";
import {MdAddPhotoAlternate, MdClose} from "react-icons/md";
import "./index.scss";
import {Tooltip, Whisper} from "rsuite";


export function ImageInput({defaultPreviewImage, previousImage, onChange, onError, errorMessage}) {


  const inputRef = useRef();
  const [touched, setTouched] = useState(false);
  const [newImage, setNewImage] = useState(null)

  previousImage = touched ? null : previousImage;

  const handleCancelInput = (e) => {
    e.preventDefault();
    inputRef.current.value = "";
    inputRef.current.type = "text";
    inputRef.current.type = "file";

    setNewImage(null)
    setTouched(true);
    onChange?.(null)
  };

  const handleChange = (e) => {
    e.preventDefault();
    const upload = e.target.files?.[0] ?? null
    setNewImage(URL.createObjectURL(upload));
    setTouched(true)
    console.log("upload", upload)
    onChange?.(upload)
  }

  const handleError = () => {
    onError?.("Invalid image file")
  }

  const tooltip = errorMessage ? <Tooltip className="text-danger">{errorMessage}</Tooltip> :
    <Tooltip>{newImage?.toString() ?? previousImage ?? "Upload Image"}</Tooltip>


  return (
    <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={tooltip}>
      <div
        className={"media-edit " + (!errorMessage ? "" : " error")}
      >
        <input
          ref={inputRef}
          accept=".png,.jpg"
          type="file"
          onChange={handleChange}
        />

        {newImage ? <img
          onError={handleError}
          alt="Invalid"
          src={newImage}
        /> : previousImage ?? defaultPreviewImage ? <img
            onError={handleError}
            alt="Invalid"
            src={previousImage ?? defaultPreviewImage}
          /> :
          (<MdAddPhotoAlternate size={30}/>)}

        {(previousImage || newImage) && (<button onClick={handleCancelInput} className="close-btn">
          <MdClose className="close-btn-content"/>
        </button>)}
      </div>
    </Whisper>
  );
}
