import Layout from "../../components/layouts/layout";
import {useNotification} from "../../hooks/useNotification";
import {useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useComponentList, useLang} from "../../hooks";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {Breadcrumb, Button} from "react-bootstrap";
import {MdDeleteOutline} from "react-icons/md";
import DataTable from "react-data-table-component";
import {Input, InputGroup} from "rsuite";
import {FaSearch, FaUserPlus} from "react-icons/fa";
import {FloatingButton} from "../../components";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";

export function ComponentListPage() {
  const {t} = useLang();
  const {
    componentList, pagination,
    changePagination, deleteComponent, isLoading, error, filterDispatch
  } = useComponentList();
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deleteComponent(id);
      notifySuccess({
        title: "components removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove component!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("components_manage_col_name"),
      selector: (row) => row.title,
      sortable: true
    },
    {
      name: t("components_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.ComponentUpdate]}>
              <Link to={`/components/edit/${m.id}`} className="btn btn-secondary">
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ComponentDelete]}>
              <Button variant="danger" onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ComponentLanguagesList]}>
              <Link to={`/langs/component/${m.id}`} className="btn btn-info">
                <BsGlobe />
              </Link>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("components_manage_newComponent")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("components_manage_newComponent")}</h2>
          <DataTable
            columns={columns}
            data={componentList.components}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={componentList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("components_manage_searchByName")} />
                <InputGroup.Button onClick={() => filterDispatch({
                  type: "set",
                  payload: {key: "term", value: searchBoxRef.current.value}
                })}>
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.ComponentStore]}>
        <FloatingButton onClick={() => {
          navigate("/components/new");
        }} icon={<FaUserPlus />} name={t("components_create_createNewComponent")} />
      </ComponentGuard>
    </>
  );
}