import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FloatingButton} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useNotification} from "../../hooks/useNotification";
import {IoLanguage} from "react-icons/io5";
import {useResourceLangList} from "../../hooks/useResourceLangList";
import {BackButton} from "../../components/BackButton/BackButton";

const resourceLangPermissionMap = {
  "taxonomy": {
    edit: Permission.TaxonomyLanguagesUpdate,
    delete: Permission.TaxonomyLanguagesDelete,
    create: Permission.TaxonomyLanguagesStore
  },
  "term": {
    edit: Permission.TermLanguagesUpdate,
    delete: Permission.TermLanguagesDelete,
    create: Permission.TermLanguagesStore
  },
  "content": {
    edit: Permission.MasterCrudLanguagesUpdate,
    delete: Permission.MasterCrudLanguagesDelete,
    create: Permission.MasterCrudLanguagesStore
  },
  "event": {
    edit: Permission.EventLanguagesUpdate,
    delete: Permission.EventLanguagesDelete,
    create: Permission.EventLanguagesStore
  },
  "post": {
    edit: Permission.PostLanguagesUpdate,
    delete: Permission.PostLanguagesDelete,
    create: Permission.PostLanguagesStore
  },
  "product": {
    edit: Permission.ProductLanguagesUpdate,
    delete: Permission.ProductLanguagesDelete,
    create: Permission.ProductLanguagesStore
  },
  "component": {
    edit: Permission.ComponentLanguagesUpdate,
    delete: Permission.ComponentLanguagesDelete,
    create: Permission.ComponentLanguagesStore
  }
};

export function LanguageListPage() {
  const {resourceName, resourceId} = useParams();
  const {notifyError, notifySuccess} = useNotification();

  const {
    list,
    pagination,
    changePagination,
    error,
    isLoading,
    deleteItem
  } = useResourceLangList({resourceName, resourceId});


  const navigate = useNavigate();
  if (error) {
    notifyError(error, "/error");
  }

  const handleDelete = async (id) => {
    try {
      await deleteItem(id);
      notifySuccess({
        title: "Resource language removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove resource language!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: "Language Code",
      selector: (row) => row.languages?.code ?? "Not Available",
      sortable: true
    },
    {
      name: "Country",
      selector: (row) => row.languages?.name ?? "Not Available",
      sortable: true
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex gap-2">
          <ComponentGuard needsPermission={[resourceLangPermissionMap[resourceName]?.edit]}>
            <Link to={`/langs/${resourceName}/${resourceId}/${row.id}/edit`} className="btn btn-secondary">
              <BsPencilSquare />
            </Link>
          </ComponentGuard>
          <ComponentGuard needsPermission={[resourceLangPermissionMap[resourceName]?.delete]}>
            <Button variant="danger" onClick={() => handleDelete(row.id)}>
              <MdDeleteOutline />
            </Button>
          </ComponentGuard>
        </div>
      )
    }
  ];

  return (
    <>
      <Layout>
        {isLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                {resourceName}
              </li>
              <Breadcrumb.Item active>Language List</Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} />
          </div>
        </header>
        <section id="post-list" className="mb-5 pb-5">
          <h2>Language/{resourceName}/{resourceId}</h2>
          <DataTable
            columns={columns}
            data={list.events}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[resourceLangPermissionMap[resourceName]?.create]}>
        <FloatingButton
          onClick={() => {
            navigate(`/lang/${resourceName}/${resourceId}/new`);
          }}
          icon={<IoLanguage />}
          name="New Language"
        />
      </ComponentGuard>
    </>
  );
}
