import {BsChevronDown, BsChevronRight, BsGlobe} from "react-icons/bs";
import {createElement} from "react";
import * as FontIcon from "react-icons/fa";
import {Button} from "react-bootstrap";
import {MdDelete, MdEdit} from "react-icons/md";
import {Tree} from "react-arborist";
import {Link} from "react-router-dom";

export function MenuTreeView({
                               data,
                               searchTerm,
                               height,
                               onItemEdit,
                               onItemDelete
                             }) {
  function Node({node, style, dragHandle}) {
    return (
      <div style={style} ref={dragHandle}>
        <MenuEditItem
          onToggle={() => {
            node.toggle();
          }}
          id={node.id}
          isLeaf={node.isLeaf}
          icon={node.data.icon}
          name={node.data.name}
          hasChild={node.children.length > 0}
          isOpen={node.isOpen}
          isTopLevel={node.data.isTopLevel}
          onEdit={onItemEdit}
          onDelete={onItemDelete}
        />
      </div>
    );
  }

  return <Tree
    openByDefault={true}
    rowHeight={40}
    disableDrag={true}
    disableDrop={true}
    height={height}
    width={600}
    data={data}
    searchTerm={searchTerm}
  >
    {Node}
  </Tree>;
}

function MenuEditItem({
                        id,
                        icon,
                        isLeaf,
                        hasChild,
                        isOpen,
                        name,
                        isTopLevel,
                        onToggle,
                        onEdit,
                        onDelete,
                        onExploreLang
                      }) {
  let nodeLeftIcon;
  let itemContainerClass = "";

  if (hasChild) {
    if (isOpen) {
      nodeLeftIcon = <BsChevronRight />;
      itemContainerClass += " text-white bg-primary";
    } else {
      itemContainerClass += " text-primary border border-primary";
      nodeLeftIcon = <BsChevronDown />;
    }
  } else {
    itemContainerClass += "bg-white";
  }

  return (
    <div className="d-flex gap-2 align-items-center">
      <div
        onClick={() => onToggle()}
        className={`flex-grow-1 px-2 py-2  rounded ${itemContainerClass}`}
      >
        <div className="d-flex gap-2">
          <div className="mr-2">{nodeLeftIcon}</div>
          <div className="">
            {createElement(FontIcon[icon] ?? FontIcon["FaLink"])}
          </div>
          <div>{name}</div>
        </div>
      </div>
      <Button variant="secondary" className="pt-1" onClick={() => onEdit(id)}>
        <MdEdit />
      </Button>
      <Button variant="danger" className="pt-1" onClick={() => onDelete(id)}>
        <MdDelete />
      </Button>
      <Link to={`/langs/menu/${id}`} className="btn btn-info">
        <BsGlobe />
      </Link>
    </div>
  );
}
