import {useLang} from "../../../hooks";
import {useEffect, useState} from "react";
import {useNotification} from "../../../hooks/useNotification";
import {usePageList} from "../../../hooks/usePageList";
import {Controller, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {MenuItemSchema} from "../schema";
import {InputValidationError} from "../../../libs/error";
import {Modal, Placeholder, SelectPicker, TreePicker} from "rsuite";
import {Button, Col, Container, Form} from "react-bootstrap";
import {IconPicker} from "react-fa-icon-picker";
import {ImageInput} from "../../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../../configs";

export function MenuItemEditForm({menu, parentList, onSubmit, onClose}) {
  const {t} = useLang();
  const [loading, setLoading] = useState();
  const {notifyError} = useNotification();
  const {pageList, isLoading: isPageLoading, error} = usePageList(null, {limit: 10000, page: 1});
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(MenuItemSchema(t))
  });

  if (error) {
    notifyError(error);
    setTimeout(onClose, 500);
  }

  useEffect(() => {
    if (menu) {
      reset({
        icon: menu.icon,
        title: menu.title,
        link: menu.link,
        description: menu.description,
        page_id: menu.page_id ? String(menu.page_id) : null,
        parent_id: menu.parent_id ? String(menu.parent_id) : null
      });
    }
  }, [menu]);
  const handleUpdate = async (data) => {
    try {
      setLoading(true);
      await onSubmit(menu.id, data);
      onClose();
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Failed to update menu item",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update",
        message: err.message
      });
    } finally {
      setLoading(false);
    }
  };
  return <Modal onClose={onClose} open={true} size={"lg"} aria-labelledby="menu-edit"
                aria-describedby="menu-edit-description">
    <Modal.Header>
      <Modal.Title id="menu-edit-title">Edit menu</Modal.Title>
    </Modal.Header>
    <Modal.Body id="modal-description">
      {loading ? <Placeholder.Graph active={true} /> :
        <Container>
          <Form
            onSubmit={handleSubmit(handleUpdate)}
            className="d-flex flex-column gap-2"
          >
            <div className="form-row align-items-center">
              <Form.Group md={1} as={Col} className="mr-2" controlId="menuName">
                <Form.Label>{t("menu_content_icon")}</Form.Label>
                <Controller
                  control={control}
                  name="icon"
                  render={({field}) => (
                    <IconPicker
                      value={field.value ?? ""}
                      onChange={field.onChange}
                      containerStyles={{
                        zIndex: 1070,
                        border: "1px solid #ced4da"
                      }}
                      searchInputStyles={{
                        padding: "0.8rem"
                      }}
                      buttonStyles={{
                        padding: "0px",
                        backgroundColor: "white",
                        border: "1px solid #ced4da"
                      }}
                    />
                  )}
                />
                {errors?.icon?.message && (
                  <Form.Text className="text-danger">
                    {errors.icon.message}
                  </Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} md={1} controlId="image">
                <Form.Label>{t("events_input_image")}</Form.Label>
                <Controller
                  control={control}
                  name="image"
                  render={({field}) => (
                    <div style={{height: "50px", width: "50px"}}>
                      <ImageInput
                        {...(menu.image && {previousImage: getUrlFromStoragePath(menu.image)})}
                        errorMessage={errors.image?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("image", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
              </Form.Group>
              <Form.Group md={6} as={Col} controlId="menuTitle">
                <Form.Label>{t("menu_content_title")}</Form.Label>
                <Form.Control
                  {...register("title")}
                  type="text"
                  placeholder={t("menu_content_title_plhd")}
                />
                {errors?.title?.message && (
                  <Form.Text className="text-danger">
                    {errors.title.message}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="menuParent">
                <Form.Label>{t("menu_content_parent")}</Form.Label>
                <Controller control={control} render={
                  ({field}) =>
                    <TreePicker
                      value={field.value}
                      onChange={field.onChange}

                      data={parentList}
                      valueKey={"value"}
                      labelKey={"label"}
                      childrenKey={"children"}
                      style={{width: "100%"}}
                    />
                } name="parent_id" />
                {errors?.parent_id?.message && (
                  <Form.Text className="text-danger">
                    {errors.parent_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Group as={Col} md={6} controlId="page_id">
                <Form.Label>{t("menu_content_page")}</Form.Label>
                <Controller control={control} render={
                  ({field}) =>
                    <SelectPicker onChange={field.onChange} loading={isPageLoading} value={field.value}
                                  data={pageList?.pages.map(p => ({
                                    label: p.title,
                                    value: String(p.id)
                                  }))} style={{width: "100%"}} />
                } name="page_id" />

                {errors?.page_id?.message && (
                  <Form.Text className="text-danger">{errors.page_id.message}</Form.Text>
                )}
              </Form.Group>
              <Form.Group as={Col} controlId="menuLink">
                <Form.Label>{t("menu_content_link")}</Form.Label>
                <Form.Control {...register("link")} />
                {errors?.link?.message && (
                  <Form.Text className="text-danger">{errors.link.message}</Form.Text>
                )}
              </Form.Group>
            </div>

            <Form.Group md={8} controlId="menuDescription">
              <Form.Label>{t("menu_content_Description")}</Form.Label>
              <Form.Control {...register("description")} as="textarea" rows={3} />
              {errors?.description?.message && (
                <Form.Text className="text-danger">
                  {errors.description.message}
                </Form.Text>
              )}
            </Form.Group>

            <div className="d-flex flex-row gap-2">
              <Button className="ml-auto" variant="success" type="submit">
                {t("menu_content_save")}
              </Button>
              <Button variant="danger" onClick={onClose}>
                {t("menu_content_cancel")}
              </Button>
            </div>
          </Form>
        </Container>}
      <div className="container"></div>
    </Modal.Body>
  </Modal>;
}