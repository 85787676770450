import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Cookies from "universal-cookie";
import "./App.css";
import {NavProvider} from "./components/NavContext/NavContext";
import ForgotPassword from "./pages/Auth/ForgotPassword.jsx";
import Login from "./pages/Auth/Login";
import DashboardPage from "./pages/Dashboard/DashboardPage";

import {UserCreatePage, UserEditPage, UserListPage} from "./pages/User";

import Role from "./pages/Role";
import DeleteRole from "./pages/Role/DeleteRole";
import RoleAssignToUser from "./pages/Role/RoleAssignToUser";
import RoleCreate from "./pages/Role/RoleCreate";
import RolePermission from "./pages/Role/RolePermission";
import RoleRevokePermission from "./pages/Role/RoleRevokePermission";
import RoleUserList from "./pages/Role/RoleUserList";

import MediaManagement from "./pages/MediaManagement/MediaListPage";

import ProtectedRoute from "./helpers/ProtectedRoute";
import NotFoundPage from "./pages/Misc/NotFoundPage";
import ForbiddenPage from "./pages/Misc/ForbiddenPage.jsx";
import {Permission} from "./configs/constants";
import ResetPassword from "./pages/Auth/ResetPassword.jsx";
import {MenuContentPage, MenuListPage, NewMenuPage} from "./pages/Menu";
import {EditMenuPage} from "./pages/Menu/EditMenuPage";
import UserProfile from "./pages/Auth/profile";
import TaxonomyListPage from "./pages/Taxonomy/TaxonomyListPage";
import {TaxonomyCreatePage} from "./pages/Taxonomy";
import {PostCreatePage, PostEditPage, PostListPage} from "./pages/post";
import {TaxonomyEditPage} from "./pages/Taxonomy/TaxonomyEditPage";
import TermListPage from "./pages/Term/TermListPage";
import {TermsCreatePage} from "./pages/Term/TermCreatePage";
import {TermEditPage} from "./pages/Term";
import ContentListPage from "./pages/Content/ContentListPage";
import {ContentCreatePage} from "./pages/Content/ContentCreatePage";
import {ContentEditPage} from "./pages/Content/ContentEditPage";
import AlbumCreatePage from "./pages/Album/AlbumCreatePage.jsx";
import AlbumEditPage from "./pages/Album/AlbumEditPage.jsx";
import AlbumListPage from "./pages/Album/AlbumListPage.jsx";
import AlbumMediaMangement from "./pages/Album/AlbumMediaMangement.jsx";
import {ProductCreatePage} from "./pages/Product/ProductCreatePage";
import ProductListPage from "./pages/Product/ProductListPage";
import {ProductEditPage} from "./pages/Product/ProductEditPage";
import {SettingsPage} from "./pages/settings";
import {ComponentCreatePage, ComponentListPage} from "./pages/Component";
import {PageListPage} from "./pages/page/PageListPage";
import {PageCreatePage} from "./pages/page/PageCreatePage";
import {PageEditPage} from "./pages/page/PageEditPage";
import {EventCreatePage, EventEditPage, EventListPage} from "./pages/event";
import {LanguageListPage} from "./pages/Language";
import {TaxonomyLanguageCreatePage} from "./pages/Taxonomy/TaxonomyLanguageCreatePage";
import {TaxonomyLanguageEditPage} from "./pages/Taxonomy/TaxonomyLanguageEditPage";
import {TermLanguageCreatePage} from "./pages/Term/TermLanguageCreatePage";
import {TermLanguageEditPage} from "./pages/Term/TermLanguageEditPage";
import {ContentLanguageCreate} from "./pages/Content/ContentLanguageCreate";
import {ContentLanguageEditPage} from "./pages/Content/ContentLanguageEditPage";
import {EventLanguageCreatePage} from "./pages/event/EventLanguageCreatePage";
import {EventLanguageEditPage} from "./pages/event/EventLanguageEditPage";
import {PostLanguageCreatePage} from "./pages/post/PostLanguageCreatePage";
import {PostLanguageEditPage} from "./pages/post/PostLanguageEditPage";
import {ProductLanguageCreatePage} from "./pages/Product/ProductLanguageCreatePage";
import {ProductLanguageEditPage} from "./pages/Product/ProductLanguageEditPage";
import {ErrorReportPage} from "./pages/Misc/ErrorReportPage";
import {ComponentEditPage} from "./pages/Component/ComponentEditPage";
import {ComponentLanguageCreate} from "./pages/Component/ComponentLanguageCreate";
import {ComponentLanguageEditPage} from "./pages/Component/ComponentLanguageEditPage";
import MediaEditPage from "./pages/MediaManagement/MediaEditPage";
import MediaCreatePage from "./pages/MediaManagement/MediaCreatePage";
import {MenuLanguageCreatePage} from "./pages/Menu/MenuLanguageCreatePage";
import {MenuLanguageEditPage} from "./pages/Menu/MenuLanguageEditPage";
import {TestimonialCreatePage} from "./pages/Testimonial/TestimonialCreatePage";
import TestimonialListPage from "./pages/Testimonial/TestimonialListPage";
import {TestimonialEditPage} from "./pages/Testimonial/TestimonialEditPage";
import {PricingCreatePage} from "./pages/pricing/PricingCreatePage";
import {PricingListPage} from "./pages/pricing/PricingListPage";
import {PricingEditPage} from "./pages/pricing/PricingEditPage";
import ContactFormListPage from "./pages/ContactForm/ContactFormListPage";
import ClientFormListPage from "./pages/ClientForm/ClientFormListPage";

const App = () => {
  const cookies = new Cookies();
  const [loggedIn, setLoggedIn] = React.useState(false);

  // const loginStatus = cookies.get('userAuth')?.token != null;

  // if(loggedIn !== loginStatus) {setLoggedIn(loginStatus)};

  useEffect(() => {
    if (cookies.get("userAuth")?.token) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <NavProvider>
      <BrowserRouter>
          <Routes>
            {cookies.get("userAuth") != true && (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:token"
                  element={<ResetPassword />}
                />
              </>
            )}
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/forbidden"
              element={
                <ForbiddenPage />
              }
            />
            {/* User Management */}
            <Route
              path="/user/profile"
              element={
                <ProtectedRoute needsPermission={[Permission.UserDetails]}>
                  <UserProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user"
              element={
                <ProtectedRoute needsPermission={[Permission.UserList]}>
                  <UserListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/create"
              element={
                <ProtectedRoute needsPermission={[Permission.UserAdd]}>
                  <UserCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.UserUpdate]}>
                  <UserEditPage />
                </ProtectedRoute>
              }
            />
            {/* Role */}
            <Route
              path="/role"
              element={
                <ProtectedRoute needsPermission={[Permission.RoleDetails]}>
                  <Role />
                </ProtectedRoute>
              }
            />
            <Route
              path="/role/create"
              element={
                <ProtectedRoute needsPermission={[Permission.RoleCreate]}>
                  <RoleCreate />
                </ProtectedRoute>
              }
            />
            <Route
              path="/role/permission/:id"
              element={
                <ProtectedRoute needsPermission={["role.givePermissionToRole"]}>
                  <RolePermission />
                </ProtectedRoute>
              }
            />
            <Route
              path="/role/revoke-permission/:id"
              element={
                <ProtectedRoute
                  needsPermission={["role.revokePermissionFromRole"]}
                >
                  <RoleRevokePermission />
                </ProtectedRoute>
              }
            />
            <Route
              path="/role/assign-role/:id"
              element={
                <ProtectedRoute needsPermission={["role.assignRoleToUser"]}>
                  <RoleAssignToUser />
                </ProtectedRoute>
              }
            />

            <Route
              path="/assign-user-role-list"
              element={
                <ProtectedRoute needsPermission={["role.userRoles"]}>
                  <RoleUserList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assign-user-role-list/delete-role/:id"
              element={
                <ProtectedRoute needsPermission={["role.removeRoleFromUser"]}>
                  <DeleteRole />
                </ProtectedRoute>
              }
            />
            {/*  Media Management */}
            <Route
              path="/media/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.MediaList]}>
                  <MediaManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/media/create"
              element={
                <ProtectedRoute needsPermission={[Permission.MediaStore]}>
                  <MediaCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/media/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.MediaGet]}>
                  <MediaEditPage />
                </ProtectedRoute>
              }
            />

            {/* Menu Management*/}
            <Route
              path="/menus/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.MenuIndex]}>
                  <MenuListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/menus/new"
              element={
                <ProtectedRoute needsPermission={[Permission.MenuStore]}>
                  <NewMenuPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/menus/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.MenuUpdate]}>
                  <EditMenuPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/menus/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.MenuStore]}>
                  <MenuContentPage />
                </ProtectedRoute>
              }
            />
            {/* Taxonomies */}
            <Route
              path="/taxonomies/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyList]}>
                  <TaxonomyListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/taxonomies/new"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyStore]}>
                  <TaxonomyCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/taxonomies/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyUpdate]}>
                  <TaxonomyEditPage />
                </ProtectedRoute>
              }
            />
            {/* Terms */}
            <Route
              path="/taxonomies/:id/terms"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyUpdate]}>
                  <TermListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/taxonomies/:id/terms/"
              element={
                <ProtectedRoute needsPermission={[Permission.TermsList]}>
                  <TermListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/taxonomies/:id/terms/new"
              element={
                <ProtectedRoute needsPermission={[Permission.TermsStore]}>
                  <TermsCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/taxonomies/:taxonomyId/terms/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.TermsUpdate]}>
                  <TermEditPage />
                </ProtectedRoute>
              }
            />
            {/* Contents(Master CRUD) */}
            <Route
              path="/contents/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.MasterCrudIndex]}>
                  <ContentListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/contents/new"
              element={
                <ProtectedRoute needsPermission={[Permission.MasterCrudStore]}>
                  <ContentCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/contents/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.MasterCrudUpdate]}>
                  <ContentEditPage />
                </ProtectedRoute>
              }
            />
            {/*  Posts */}
            <Route
              path="/posts/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.PostsList]}>
                  <PostListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/posts/new"
              element={
                <ProtectedRoute needsPermission={[Permission.PostsStore]}>
                  <PostCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/posts/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.PostsUpdate]}>
                  <PostEditPage />
                </ProtectedRoute>
              }
            />
            {/*  Albums */}
            <Route
              path="/albums/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyList]}>
                  <AlbumListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/albums/new"
              element={
                <ProtectedRoute needsPermission={[Permission.GalleryStore]}>
                  <AlbumCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/albums/edit/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.GalleryUpdate]}>
                  <AlbumEditPage />
                </ProtectedRoute>
              }
            />
            {/* Will Remove Later. */}
            <Route
              path="/albums/edit/:id/media"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyStore]}>
                  <AlbumMediaMangement />
                </ProtectedRoute>
              }
            />
            {/* Product */}
            <Route
              path="/products/new"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <ProductCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/products/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductIndex]}>
                  <ProductListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/products/edit/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductUpdate]}>
                  <ProductEditPage />
                </ProtectedRoute>
              }
            />
            {/* Events */}
            <Route
              path="/events/new"
              element={
                <ProtectedRoute needsPermission={[Permission.EventStore]}>
                  <EventCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/events/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.EventList]}>
                  <EventListPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/events/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.EventUpdate]}>
                  <EventEditPage />
                </ProtectedRoute>
              }
            />
            {/* Components */}
            <Route
              path="/components/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.ComponentList]}>
                  <ComponentListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/components/new"
              element={
                <ProtectedRoute needsPermission={[Permission.ComponentStore]}>
                  <ComponentCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/components/edit/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.ComponentUpdate]}>
                  <ComponentEditPage />
                </ProtectedRoute>
              }
            />
            {/*Pages*/}
            <Route
              path="/pages/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.PageList]}>
                  <PageListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pages/new"
              element={
                <ProtectedRoute needsPermission={[Permission.PageStore]}>
                  <PageCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pages/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.PageUpdate]}>
                  <PageEditPage />
                </ProtectedRoute>
              }
            />
            {/*Pages*/}
            <Route
              path="/pricing/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.PageList]}>
                  <PricingListPage/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing/new"
              element={
                <ProtectedRoute needsPermission={[Permission.PageStore]}>
                  <PricingCreatePage/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/pricing/:id/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.PageUpdate]}>
                  <PricingEditPage/>
                </ProtectedRoute>
              }
            />
            {/* Testimonial */}
            <Route
              path="/testimonials/new"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <TestimonialCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/testimonials/manage"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <TestimonialListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/testimonials/edit/:id"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <TestimonialEditPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/contact-forms/"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <ContactFormListPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/client-forms/"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductStore]}>
                  <ClientFormListPage />
                </ProtectedRoute>
              }
            />
            {/*  Languages */}
            <Route
              path="langs/:resourceName/:resourceId/"
              element={
                <ProtectedRoute>
                  <LanguageListPage />
                </ProtectedRoute>
              }
            />
            {/* Taxonomy Language Page*/}
            <Route
              path="/lang/taxonomy/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyLanguagesStore]}>
                  <TaxonomyLanguageCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/taxonomy/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.TaxonomyLanguagesUpdate]}>
                  <TaxonomyLanguageEditPage />
                </ProtectedRoute>
              }
            />
            {/* Terms Permission */}
            <Route
              path="/lang/term/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.TermLanguagesStore]}>
                  <TermLanguageCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/term/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.TermLanguagesUpdate]}>
                  <TermLanguageEditPage />
                </ProtectedRoute>
              }
            />
            {/* Contents (Master CRUD) Language */}
            <Route
              path="/lang/content/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.MasterCrudLanguagesStore]}>
                  <ContentLanguageCreate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/content/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.MasterCrudLanguagesUpdate]}>
                  <ContentLanguageEditPage />
                </ProtectedRoute>
              }
            />
            {/* Events Languages */}
            <Route
              path="/lang/event/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.EventLanguagesStore]}>
                  <EventLanguageCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/langs/event/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.EventLanguagesUpdate]}>
                  <EventLanguageEditPage />
                </ProtectedRoute>
              }
            />
            {/* Post Languages */}
            <Route
              path="/lang/post/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.PostLanguagesStore]}>
                  <PostLanguageCreatePage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/langs/post/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.PostLanguagesUpdate]}>
                  <PostLanguageEditPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/lang/product/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductLanguagesStore]}>
                  <ProductLanguageCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/product/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.ProductLanguagesUpdate]}>
                  <ProductLanguageEditPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/lang/component/:resourceId/new"
              element={
                <ProtectedRoute needsPermission={[Permission.ComponentLanguagesStore]}>
                  <ComponentLanguageCreate />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/component/:resourceId/:langId/edit"
              element={
                <ProtectedRoute needsPermission={[Permission.ComponentLanguagesUpdate]}>
                  <ComponentLanguageEditPage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/lang/menu/:resourceId/new"
              element={
                <ProtectedRoute>
                  <MenuLanguageCreatePage />
                </ProtectedRoute>
              }
            />

            <Route
              path="/langs/menu/:resourceId/:langId/edit"
              element={
                <ProtectedRoute>
                  <MenuLanguageEditPage />
                </ProtectedRoute>
              }
            />
            {/* Settings */}
            <Route
              path="/settings"
              element={
                <SettingsPage />
              }
            />
            <Route path="/error" element={<ErrorReportPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
    </NavProvider>
  );
};
export default App;
