export const Permission = Object.freeze({
  // Auth Permission List
  AuthLogin: "auth.login",
  AuthRefreshToken: "auth.refreshToken",
  AuthForgotPassword: "auth.forgotPassword",
  AuthResetPassword: "auth.resetPassword",
  // User Permission List
  UserLogout: "user.logout",
  UserAdd: "user.add",
  UserUpdate: "user.update",
  UserList: "user.list",
  UserDetails: "user.details",
  // Role Permission List
  RoleCreate: "role.create",
  RoleUserRoles: "role.userRoles",
  RoleUserRoleSearch: "role.userRoleSearch",
  RoleUserDetails: "role.userDetails",
  RoleAssignRoleToUser: "role.assignRoleToUser",
  RoleRemoveRoleFromUser: "role.removeRoleFromUser",
  RoleGet: "role.get",
  RoleDetails: "role.details",
  RoleSearch: "role.search",
  RoleAssignSuperAdminToUser: "role.assignSuperAdminToUser",
  RoleRevokePermissionFromRole: "role.revokePermissionFromRole",
  RoleGivePermissionToRole: "role.givePermissionToRole",
  RoleDelete: "role.delete",
  // Permission List 😅
  PermissionAddRouteAsPermission: "permission.addRouteAsPermission",
  PermissionAll: "permission.all",
  PermissionGetAll: "permission.getAll",
  PermissionSearch: "permission.search",
  // Media Permission List
  MediaList: "media.list",
  MediaStore: "media.store",
  MediaGet: "media.get",
  MediaCategories: "media.categories",
  MediaSearchByFilter: "media.searchByFilter",
  MediaUpdate: "media.update",
  MediaDelete: "media.delete",
  GalleryAllImages: "gallery.allImages",
  GalleryAlbumNames: "gallery.albumNames",
  GalleryOneAlbum: "gallery.oneAlbum",
  GalleryVisitAlbum: "gallery.visitAlbum",
  GalleryStore: "gallery.store",
  GalleryGet: "gallery.get",
  GallerySearchByAlbumTitle: "gallery.searchByAlbumTitle",
  GalleryUpdate: "gallery.update",
  GalleryDelete: "gallery.delete",
  // Menu Permission List
  MenuIndex: "menu.index",
  MenuStore: "menu.store",
  MenuUpdate: "menu.update",
  MenuTypeById: "menu.menu-type-by-id",
  MenuCategories: "menu.categories",
  MenuDestroy: "menu.destroy",
  MenuLanguagesIndex: "menu_languages.index",
  MenuLanguagesStore: "menu_languages.store",
  MenuLanguagesGet: "menu_languages.get",
  MenuLanguagesUpdate: "menu_languages.update",
  MenuLanguagesDelete: "menu_languages.delete",
  MenuAll: "menu.all",
  // Taxonomy Permission List
  TaxonomyList: "taxonomy.list",
  TaxonomyGet: "taxonomy.get",
  TaxonomyStore: "taxonomy.store",
  TaxonomyUpdate: "taxonomy.update",
  TaxonomyDelete: "taxonomy.delete",
  TaxonomySearch: "taxonomy.search",
  TaxonomySearchAll: "taxonomy.searchAll",
  TaxonomyGetLanguage: "taxonomy.getLanguage",
  TaxonomyLanguagesList: "taxonomy_languages.list",
  TaxonomyLanguagesStore: "taxonomy_languages.store",
  TaxonomyLanguagesGet: "taxonomy_languages.get",
  TaxonomyLanguagesSearch: "taxonomy_languages.search",
  TaxonomyLanguagesSearchAll: "taxonomy_languages.searchAll",
  TaxonomyLanguagesUpdate: "taxonomy_languages.update",
  TaxonomyLanguagesDelete: "taxonomy_languages.delete",
  // Terms Permission List
  TermsList: "terms.list",
  TermsStore: "terms.store",
  TermsGet: "terms.get",
  TermsByTaxonomyID: "terms.terms-by-Ftaxonomyid",
  TermsSearch: "terms.search",
  TermsSearchAll: "terms.searchAll",
  TermsUpdate: "terms.update",
  TermsDelete: "terms.delete",
  TermLanguagesList: "term_languages.list",
  TermLanguagesStore: "term_languages.store",
  TermLanguagesGet: "term_languages.get",
  TermLanguagesSearch: "term_languages.search",
  TermLanguagesSearchAll: "term_languages.searchAll",
  TermLanguagesUpdate: "term_languages.update",
  TermLanguagesDelete: "term_languages.delete",
  // Contents(Master CRUD) Permission List
  MasterCrudIndex: "master_crud.index",
  MasterCrudStore: "master_crud.store",
  MasterCrudGet: "master_crud.get",
  MasterCrudCategories: "master_crud.categories",
  MasterCrudUpdate: "master_crud.update",
  MasterCrudDelete: "master_crud.delete",
  MasterCrudLanguagesIndex: "master_crud_languages.index",
  MasterCrudLanguagesGet: "master_crud_languages.get",
  MasterCrudLanguagesStore: "master_crud_languages.store",
  MasterCrudLanguagesUpdate: "master_crud_languages.update",
  MasterCrudLanguagesDelete: "master_crud_languages.delete",
  // Posts Permission List
  PostsList: "posts.list",
  PostsStore: "posts.store",
  PostsGet: "posts.get",
  PostsCategories: "post.categories",
  PostsUpdate: "posts.update",
  PostsDelete: "posts.delete",
  PostLanguagesList: "post_languages.list",
  PostLanguagesStore: "post_languages.store",
  PostLanguagesGet: "post_languages.get",
  PostLanguagesSearch: "post_languages.search",
  PostLanguagesSearchAll: "post_languages.searchAll",
  PostLanguagesUpdate: "post_languages.update",
  PostLanguagesDelete: "post_languages.delete",
  // Event Permission List
  EventList: "event.list",
  EventStore: "event.store",
  EventGet: "event.get",
  EventCategories: "event.categories",
  EventUpdate: "event.update",
  EventDelete: "event.delete",
  EventLanguagesList: "event_languages.list",
  EventLanguagesStore: "event_languages.store",
  EventLanguagesGet: "event_languages.get",
  EventLanguagesUpdate: "event_languages.update",
  EventLanguagesDelete: "event_languages.delete",
  // Product Permission List
  ProductIndex: "product.index",
  ProductCategoryList: "product.categoryList",
  ProductAttributesList: "product.attributeList",
  ProductStore: "product.store",
  ProductGet: "product.get",
  ProductUpdate: "product.update",
  ProductDestroy: "product.destroy",
  ProductLanguagesIndex: "product_languages.index",
  ProductLanguagesStore: "product_languages.store",
  ProductLanguagesGet: "product_languages.get",
  ProductLanguagesUpdate: "product_languages.update",
  ProductLanguagesDelete: "product_languages.destroy",
  // Contents Permission List
  ContentList: "master_crud.index",
  ContentCreate: "master_crud.store",
  ContentUpdate: "master_crud.update",
  ContentDelete: "master_crud.delete",
  // Post Permission List
  PostList: "post.list",
  PostCreate: "post.create",
  PostGet: "post.get",
  PostDelete: "post.delete",
  PostUpdate: "post.update",
  // components Permission List
  ComponentList: "component.list",
  ComponentStore: "component.store",
  ComponentGet: "component.get",
  ComponentGetIdentifierList: "component.getIdentifierList",
  ComponentCategories: "component.categories",
  ComponentItemList: "component.itemList",
  ComponentUpdate: "component.update",
  ComponentDelete: "component.delete",
  ComponentLanguagesList: "component_languages.list",
  ComponentLanguagesStore: "component_languages.store",
  ComponentLanguagesGet: "component_languages.get",
  ComponentLanguagesUpdate: "component_languages.update",
  ComponentLanguagesDelete: "component_languages.delete",
  ComponentLanguagesItemList: "component_languages.itemList",
  // Page Permission List
  PageList: "page.list",
  PageStore: "page.store",
  PageGet: "page.get",
  PageUpdate: "page.update",
  PageDelete: "page.delete",
  PageLanguageList: "page_language.list",
  PageLanguageStore: "page_language.store",
  PageLanguageGet: "page_language.get",
  PageLanguageUpdate: "page_language.update",
  PageLanguageDelete: "page_language.delete",
  //Forms Permissions List
  ClientForm: "clientForms.get",
  ContactForm: "contactForms.get",
  //Dashboard Activity
  ActivityList: "activity.All",
  // Setting Permission List
  GeneralSettingsIndex: "general_setting.index",
  GeneralSettingsUpdate: "general_setting.update",
  SocialMediaIndex: "social_media.index",
  SocialMediaUpdate: "social_media.update",
  CommonSettingIndex: "common_setting.index",
  CommonSettingUpdate: "common_setting.update",
  HeaderSettingIndex: "header_setting.index",
  HeaderSettingUpdate: "header_setting.update",
  FooterOneSettingIndex: "footer_one_setting.index",
  FooterOneSettingUpdate: "footer_one_setting.update",
  FooterTwoSettingIndex: "footer_two_setting.index",
  FooterTwoSettingUpdate: "footer_two_setting.update",
  FooterThreeSettingIndex: "footer_three_setting.index",
  FooterThreeSettingUpdate: "footer_three_setting.update",
  // Others Permission List
  GsOptionLanguagesIndex: "gs_option_languages.index",
  GsOptionLanguagesStore: "gs_option_languages.store",
  GsOptionLanguagesGet: "gs_option_languages.get",
  GsOptionLanguagesUpdate: "gs_option_languages.update",
  GsOptionLanguagesDelete: "gs_option_languages.delete",

  LanguagesAll: "languages.all",
  SlugPage: "slug.page",
  ActivityAll: "activity.All",
  DashboardGetData: "dashboard.getData",
  LanguagesSearch: "languages.search"
});