import {useEffect} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {useLang} from "../../hooks";
import {MenuItemLanguageSchema} from "./schema";
import {useMenu} from "./hooks/useMenu";

export function MenuLanguageEditPage() {
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {t} = useLang();

  const {menu, isLoading: isContentLoading, menuError} = useMenu(resourceId);
  const {language, isLoading, error, edit} = useLanguage("menu", langId);

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError || menuError) {
    notifyError(error, "/error");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue
  } = useForm({
    resolver: yupResolver(MenuItemLanguageSchema(t))
  });

  useEffect(() => {
    if (language) {
      setValue("language_id", language.language_id ?? "");
      setValue("title", language.title ?? "");
      setValue("description", language.description ?? "");
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      await edit({...data, menu_id: resourceId, order: menu.order, menu_type_id: menu.menu_type_id, link: menu.link});
      notifySuccess({
        title: `Language  updated successfully`
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading || isContentLoading) && <CircleLoader />}
      <section id="#menu-language-create">
        <header>
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to={`/menus/manage`}>Contents</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/menu/${resourceId}`}>{menu?.title} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit language/ {langId}</Breadcrumb.Item>
          </Breadcrumb>
          <h3>Edit Language ({menu?.name ?? resourceId})</h3>
        </header>
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors} />

          <Form.Group controlId="menuTitle">
            <Form.Label>{t("menu_content_title")}</Form.Label>
            <Form.Control
              {...register("title")}
              type="text"
              placeholder={t("menu_content_title_plhd")}
            />
            {errors?.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="menuDescription">
            <Form.Label>{t("menu_content_Description")}</Form.Label>
            <Form.Control {...register("description")} as="textarea" rows={3} />
            {errors?.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>

          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
