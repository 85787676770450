import {api, parseAPIError} from "./api";

export function processContentInput(input) {
  return input
}

export async function createContent(input) {
  try {
    const r = await api.post("master_crud/store", processContentInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getContent(id) {
  const r = await api.get(`terms/get/${id}`);
  return r.data.data
}

export async function updateContent(id, input) {
  try {
    const r = await api.post(`terms/update/${id}`, processContentInput(input));
    return r.data.data
  } catch (err) {
    throw parseAPIError(err)
  }
}

export async function getPaginatedContent(options) {
  const r = await api.get("/master_crud/show", {
    params: options
  })
  if (r.data.data) {
    return r.data.data
  }
  return {
    data: [],
    total: 0,
  }
}

export async function removeContent(id) {
  const r = await api.delete(`/master_crud/delete/${id}`);
  return r.data.data
}
