import {api, parseAPIError} from "../../services/api";

function processMenuItemInput(input) {
  return {...input, order: 1};
}

export async function updateMenuItem(id, input) {
  try {
    const r = await api.post(`/menu/update/${id}`, processMenuItemInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function getMenuItem(id) {
  // TODO: TELL BACKEND TO CREATE THIS API
  const r = await api.get(`/menu/show`);
  const item = r.data?.data?.data?.filter?.(m => String(m.id) === String(id))[0];
  if (!item) {
    throw new Error("Menu Item not found");
  }
  return item;
}

export async function getMenuContent(menuId) {
  try {
    const r = await api.get(`/menu/menu-type-by-id/${menuId}`, {
      params: {
        limit: 1000
      }
    });
    if (r.data.data) {
      return r.data.data;
    } else {
      return [];
    }
  } catch (err) {
    if (err.response.status === 404) {
      return [];
    } else {
      throw err;
    }
  }
}

export async function createMenuItem(input) {
  try {
    const r = await api.post("/menu/store", processMenuItemInput(input));
    return r.data.data;
  } catch (err) {
    throw parseAPIError(err);
  }
}

export async function removeItem(id) {
  const r = await api.delete(`/menu/destroy/${id}`);
  return r.data.data;
}

export function convertMenuListToTreeArray(menuList) {
  let menuMap = {};
  for (const item of menuList) {
    // Creating current node
    const nameElement = item.title;
    const isTopLevel = item.parent_id ? false : true;
    if (menuMap[item.id]) {
      menuMap[item.id].name = nameElement;
      menuMap[item.id].isTopLevel = isTopLevel;
      menuMap[item.id].icon = item.icon;
    } else {
      menuMap[item.id] = {
        id: String(item.id),
        name: nameElement,
        isTopLevel: isTopLevel,
        icon: item.icon,
        children: []
      };
    }
    const currentNode = menuMap[item.id];

    // Assigning current node parent node
    if (item.parent_id !== null && item.parent_id !== undefined) {
      if (menuMap[item.parent_id]) {
        const parentNode = menuMap[item.parent_id];
        if (parentNode.children?.length > 0) {
          parentNode.children.push(currentNode);
        } else {
          parentNode.children = [currentNode];
        }
      } else {
        menuMap[item.parent_id] = {
          id: String(item.parent_id),
          children: [currentNode]
        };
      }
    }
  }

  return Object.values(menuMap).filter((node) => node.isTopLevel);
}
