export function arrayToObject(arrayName, array) {
  const obj = {}
  for (let i = 0; i < array.length; i++) {
    obj[`${arrayName}[${i}]`] = array[i]
  }
  return obj
}

export function jsonToFormData(requestData) {
  const formData = new FormData();
  Object.entries(requestData).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // If value is an array, append each element separately
      value.forEach((element, index) => {
        formData.append(`${key}[${index}]`, element);
      });
    } else {
      // Otherwise, append as a single field
      formData.append(key, value);
    }
  });
  return formData
}

export function organizeDataIntoTree(dataList, parentId = null) {
  const children = dataList
    .filter(item => item.parent_id === parentId)
    .map(item => ({
      value: String(item.id), // Assuming 'id' is unique and can be used as value
      label: item.name,
      // Recursively call organizeDataIntoTree to find children of the current item
      children: organizeDataIntoTree(dataList, item.id),
      // Other properties like 'groupBy' or 'loading' can be added here if needed
    }));

  return children.length > 0 ? children : undefined;
}
