import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {getUrlFromStoragePath} from "../../configs";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {TermLanguageSchema} from "./termSchema";
import {useLang, useTerm} from "../../hooks";
import {processTermInput} from "../../services/term";
import {BackButton} from "../../components/BackButton/BackButton";

export function TermLanguageEditPage() {
  const {t} = useLang();
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {language, isLoading, error, edit} = useLanguage("term", langId);
  const {term, isLoading: isTermLoading, termError} = useTerm(resourceId)

  const {langs, isLoading: isLangLoading, error: isLangError} = useLanguages();

  if (error || isLangError || termError) {
    notifyError(error)
    navigate(-1);
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    setValue,
  } = useForm({
    resolver: yupResolver(TermLanguageSchema()),
  });

  useEffect(() => {
    if (language) {
      setValue("name", language.name);
      setValue("description", language.description);
      setValue("language_id", language.language_id);
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      data.image = data?.image ?? term?.image ?? null
      await edit({...processTermInput(data), term_id: resourceId});
      notifySuccess({
        title: `Language  updated successfully`,
      });
      navigate(-1);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(isLoading || isLangLoading || isTermLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              {t("terms_manage_terms")}
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/term/${resourceId}`}>{term?.name ?? resourceId} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit Language</Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("users_page_title")}/>
        </div>
      </header>
      <section id="#term-language-create">
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>

          <LanguageSelector loading={isLangLoading} control={control} langs={langs} errors={errors}/>
          <div className="form-row">
            <Form.Group as={Col} md={2}>
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <ImageInput
                    {...(term?.image && {defaultPreviewImage: getUrlFromStoragePath(term.image)})}
                    {...(language?.image && {previousImage: getUrlFromStoragePath(language.image)})}
                    errorMessage={errors.image?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("image", {message: "Invalid image file!"})
                    }}
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="flex-grow-1" controlId="name">
              <Form.Label className="mark-required">Name</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder="Title of Term"
                type="text"
              />
              {errors.name?.message && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>

          <Form.Group controlId="description">
            <Form.Label className="mark-required">Description</Form.Label>
            <Form.Control
              {...register("description")}
              placeholder="Term description"
              as="textarea"
              rows={3}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
