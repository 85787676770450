import {useEffect} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SelectPicker} from "rsuite";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLang, useTerm, useTermList} from "../../hooks";
import {TermInputSchema} from "./termSchema";
import useTaxonomy from "../../hooks/useTaxonomy";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {getUrlFromStoragePath} from "../../configs";
import {BackButton} from "../../components/BackButton/BackButton";

export function TermEditPage() {
  const {t} = useLang();
  const {id, taxonomyId} = useParams();
  const {taxonomy, isLoading: taxonomyLoading, error: taxonomyError} = useTaxonomy(taxonomyId)
  const {term, editTerm, isLoading, error} = useTerm(id);
  const {termList, isTermLoading} = useTermList(taxonomyId)
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setValue,
    setError,
    watch,
  } = useForm({
    resolver: yupResolver(TermInputSchema(t)),
  });

  const name = watch("name");

  if (taxonomyError || error) {
    notifyError(error);
    setTimeout(() => navigate(`/taxonomies/${taxonomyId}/terms`), 500);
  }


  useEffect(() => {
    if (term) {
      setValue("name", term.name ?? "");
      setValue("description", term.description ?? "");
      term?.parent_id && setValue("parent_id", term.parent_id);
    }
  }, [term]);

  const handleEdit = async (data) => {
    try {
      if (!data.description) {
        delete data.description;
      }
      await editTerm({...data, taxonomy_id: term.taxonomies.id});
      notifySuccess({
        title: `Term "${term.name}" updated successfully`,
      });
      navigate(`/taxonomies/${taxonomyId}/terms`);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to update term!",
        message: err.message,
      });
    }
  };

  return (
    <Layout>
      {(taxonomyLoading || isLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/taxonomies/${taxonomyId}/terms`}> {t("terms_manage_terms")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("terms_edit_editTerm")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("users_page_title")}/>
        </div>
      </header>
      <section id="term-create">
        <h2>{taxonomy?.name ?? "Taxonomy"} / {t("terms_edit_editTerm")}: {name}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(handleEdit)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={2}>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <ImageInput
                  errorMessage={errors.image?.message}
                  {...(term?.image && {previousImage: getUrlFromStoragePath(term.image)})}
                  onChange={field.onChange}
                  onError={() => {
                    setError("image", {message: "Invalid image file!"})
                  }}
                />
              )}
            />
          </Form.Group>
          <Form.Group as={Col} md={2} controlId="parent_id">
            <Form.Label>{t("terms_input_term")}</Form.Label>
            <div>
              <Controller render={({field}) => <SelectPicker
                onChange={field.onChange}
                value={field.value}
                style={{width: "100%"}}
                data={termList.terms.map(t => ({value: t.id, label: t.name}))}
                placeholder={t("terms_input_term_p")}
                loading={isTermLoading}
              />
              } name="parent_id" control={control}/>
            </div>
            {errors.parent_id?.message && (
              <Form.Text className="text-danger">
                {errors.parent_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("terms_input_name")}</Form.Label>
            <Form.Control
              {...register("name")}
              placeholder={t("terms_input_name_p")}
              type="text"
            />
            {errors.name?.message && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="description">
          <Form.Label>{t("terms_input_description")}</Form.Label>
          <Form.Control
            {...register("description")}
            placeholder={t("terms_input_description_p")}
            as="textarea"
            rows={3}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>
        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("terms_edit_save")}
          </Button>
          <Link className="btn btn-danger" to={`/taxonomies/${taxonomyId}/terms`}>
            {t("terms_edit_cancel")}
          </Link>
        </div>
      </Form>
    </Layout>
  );
}
