import {Modal, Pagination} from "rsuite"
import {Badge, Button, Card, Container, Nav} from "react-bootstrap";
import {useTermList} from "../../hooks";
import {useState} from "react"
import {useComponentList} from "../../hooks/useComponentList";
import "./index.css";
import {getUrlFromStoragePath} from "../../configs";

export  function  ComponentSelector ({open, handleClose, onComponentSelect, insertIdx}) {
    const {termList} = useTermList(10, {page: 1, limit: 1000})
    const {
        componentList,
        pagination,
        changePagination,
        filterDispatch
    } = useComponentList({}, {page: 1, limit:10})

    const handleCategoryChange = (id) => {
        if (id === -1) {
            filterDispatch({
                type: "clear"
            })
            return
        }

        filterDispatch({
            type: "set",
            payload: {
                "key": "category",
                "value": id,
            }
        })
    }

    return <Modal size="full" open={open} onClose={handleClose}>
        <Modal.Header>
            <Modal.Title><h5>Select Component</h5></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
                    <div className="mt-2 d-flex flex-wrap gap-2">
                        <Button size="sm" variant="outline-primary" className="rounded-pill" onClick={() => handleCategoryChange(-1)}>
                            All
                        </Button>
                        {termList.terms?.length > 0 && termList.terms.map(t => {
                            return <Button size="sm" variant="outline-primary" className="rounded-pill" onClick={() => handleCategoryChange(t.id)}>{t.name.replace(/ Component$/, "")}</Button>
                        })}
                    </div>

                    <div className="flex-grow-1">
                        <div className="masonry" style={{width: "80vw"}}>
                            {componentList.components.map(c => <Card key={c.id} className="component-card"
                                                                     onClick={() => {
                                                                         onComponentSelect(insertIdx, {
                                                                             name: c.component_tag,
                                                                             component_id: c.id,
                                                                             title: c.title,
                                                                             details: {
                                                                                 title: c.title,
                                                                                 image1: c.image1,
                                                                                 image2: c.image2,
                                                                                 image3: c.image3,
                                                                             }
                                                                         })
                                                                     }}>
                                  {(c.image1 || c.image2 || c.image3) && <Card.Img variant="top"
                                                                                   src={getUrlFromStoragePath(c.image1 ?? c.image2 ?? c.image3)} />}
                                  <Card.Body>
                                      <Card.Title>{c.title}</Card.Title>
                                      {c.description && <Card.Text>
                                          {c.description}
                                      </Card.Text>}
                                  </Card.Body>
                              </Card>
                            )}
                        </div>

                    </div>
            </Container>

        </Modal.Body>

        <Modal.Footer className="pt-4 d-flex">
            {componentList.total > pagination.limit &&
              <div
                className="flex-grow-1 d-flex justify-content-center"
              >
                  <div>
                      <Pagination
                        className="m-auto"
                        prev
                        last
                        next
                        first
                        size="lg"
                        total={componentList.total}
                        limit={pagination.limit}
                        activePage={pagination.page}
                        onChangePage={(page) => {
                            changePagination({page: page})
                        }}
                      />
                  </div>
              </div>
            }
            <Button onClick={handleClose} variant="danger">
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}