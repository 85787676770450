import * as yup from "yup";
import {defaultImageValidation, DefaultLinkValidation, languageSchema} from "../../configs/constants/validation";
import {phoneRegExp} from "../../configs/constants";

export const GeneralSettingsSchema = (t) => yup.object({
  site_title: yup.string().max(60).required().label(t("settings_general_siteTitle")),
  tag_line: yup.string().max(255).required().label(t("settings_general_tagLine")),
  site_address: yup.string().url().required().label(t("settings_general_siteAddress")),
  email_address: yup.array().of(yup.string().email()).max(50).label(t("settings_header_clientEmail")),
  roboket_chat_base_url: yup.string().url().required().label(t("settings_general_roboketChatBaseUrl")),
  roboket_chat_website_token: yup.string().required().label(t("settings_general_roboketChatWebsiteToken")),
});

export const SocialSettingsSchema=(t) => yup.object({
  facebook: yup.string().label("Facebook URL").required().label(t("social_settings_facebook")),
  instagram: yup.string().label("Instagram URL").required().label(t("social_settings_instagram")),
  linkedin: yup.string().label("Linkedin URL").required().label(t("social_settings_linkedin")),
  youtube: yup.string().label("Youtube URL").label(t("social_settings_youtube"))
});

export const CommonSettingsSchema=(t) => yup.object({
  is_blog: yup.string().oneOf(["yes", "no"]).required(),
  is_ecommerce: yup.string().oneOf(["yes", "no"]).required()
}).required();

export const HeaderSettingsSchema = (t) => yup.object({
  site_address_header: yup.string().max(60).required().label(t("settings_header_site_address")),
  site_email: yup.string().email().required().label(t("settings_header_email")),
  site_phone: yup.string().matches(phoneRegExp).required().label(t("settings_header_phone")),
  main_menu_id: yup.string().nullable().label(t("settings_header_main_menu")),
  mobile_menu_id: yup.string().nullable().label(t("settings_header_mobile_menu")),
  logo: defaultImageValidation.label(t("settings_site_logo")),
  alter_text: yup.string().label(t("settings_header_logo_alt_text")),
  button_title: yup.string().required().label(t("settings_header_button_txt")),
  button_link: yup.string().url().required().label(t("settings_header_button_link"))
});


export const FooterSettingsOneSchema = (t) => yup.object({
  footer_title_one: yup.string().required().label(t("settings_footer_title")),
  footer_content_one: yup.string().label(t("settings_footer_content")),
  footer_logo_one: defaultImageValidation.label(t("settings_site_footer_logo")),
  footer_is_social_one: yup.string().oneOf(["1", "0"]).default("0").label(t("settings_footer_is_social")),
  footer_menu_id_one: yup.string().nullable().label(t("settings_footer_menu"))
});


export const FooterSettingsTwoSchema = (t) => yup.object({
  footer_title_two: yup.string().required().label(t("settings_footer_title")),
  footer_content_two: yup.string().label(t("settings_footer_content")),
  footer_logo_two: defaultImageValidation.label(t("settings_site_footer_logo")),
  footer_is_social_two: yup.string().oneOf(["1", "0"]).default("0").label(t("settings_footer_is_social")),
  footer_menu_id_two: yup.string().nullable().label(t("settings_footer_menu"))
});

export const FooterSettingsThreeSchema = (t) => yup.object({
  footer_title_three: yup.string().required().label(t("settings_footer_title")),
  footer_content_three: yup.string().label(t("settings_footer_content")),
  footer_logo_three: defaultImageValidation.label(t("settings_site_footer_logo")),
  footer_is_social_three: yup.string().oneOf(["1", "0"]).default("1").label(t("settings_footer_is_social")),
  footer_menu_id_three: yup.string().nullable().label(t("settings_footer_menu"))
});

export const SettingsLanguageSchema = () => languageSchema.shape({
  value: yup.string().required().label("Value")
});
