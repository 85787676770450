import {useEffect} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {useLanguages} from "../../hooks/useLanguages";
import {LanguageSelector} from "../../components/Language/LanguageSelector";
import {useLanguage} from "../../hooks/useLanguage";
import {getUrlFromStoragePath} from "../../configs";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {BackButton} from "../../components/BackButton/BackButton";
import {useLang} from "../../hooks";
import {ComponentLanguageSchema} from "./schema";
import useComponent from "../../hooks/useComponent";

export function ComponentLanguageEditPage() {
  const {t} = useLang();
  const {langId, resourceId} = useParams();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {language, isLoading, error, edit} = useLanguage("component", langId);
  const {component, isLoading: compnentLoading, error: componentErr} = useComponent(resourceId);
  const {langs, isLoading: langLoading, error: langErr} = useLanguages();

  if (error || langErr || componentErr) {
    notifyError(error ?? langErr ?? componentErr, "/error");
  }

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    reset
  } = useForm({
    resolver: yupResolver(ComponentLanguageSchema())
  });

  useEffect(() => {
    if (language) {
      const {image, ...prev} = language;
      reset(prev);
    }
  }, [language]);


  const handleEdit = async (data) => {
    try {
      if (!data.image) {
        data.image = language.image ? null : "same_as_parent";
      }
      await edit({...data, component_id: resourceId});
      notifySuccess({
        title: `Language  updated successfully`
      }, `/langs/component/${resourceId}/`);
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update language!",
        message: err.message
      });
    }
  };

  return (
    <Layout>
      {(isLoading || langLoading || compnentLoading) && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={"/taxonomies/manage"}>{t("taxonomies_manage_taxonomies")}</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/langs/component/${resourceId}`}>{component?.name} Languages</Link>
            </li>
            <Breadcrumb.Item active>Edit language</Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("taxonomies_manage_taxonomies")} />
        </div>
      </header>
      <section id="#component-create">
        <Form
          onSubmit={handleSubmit(handleEdit)}
          className="mt-4 d-flex  flex-column gap-3"
        >

          <LanguageSelector loading={langLoading} control={control} langs={langs} errors={errors} />

          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">{t("components_create_title")}</Form.Label>
            <Form.Control {...register("title")} placeholder={t("components_create_title_plhd")}
                          type="text" />
            {errors.title?.message && (
              <Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
          </Form.Group>
          <Form.Group className="flex-grow-1 mt-2" controlId="description">
            <Form.Label className="mark-required">{t("components_create_description")}</Form.Label>
            <Form.Control {...register("description")}
                          placeholder={t("components_create_description_plhd")} as="textarea" rows={3} />
            {errors.description?.message && (
              <Form.Text className="text-danger">{errors.description.message}</Form.Text>)}
          </Form.Group>

          <Form.Group controlId="image">
            <Form.Label>{t("posts_create_image")}</Form.Label>
            <div className="w-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <div style={{height: "300px"}}>
                    <ImageInput
                      {...(component?.image && {defaultPreviewImage: getUrlFromStoragePath(component.image)})}
                      errorMessage={errors.image?.message}
                      onChange={field.onChange}
                      onError={() => {
                        setError("image", {message: "Invalid image file!"});
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </Form.Group>
          <div className="d-flex gap-2 justify-content-end">
            <Button className="px-4" type="submit">
              Save
            </Button>
            <Link to={-1} className="px-4 btn btn-danger">
              Cancel
            </Link>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
