import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useLang, usePrivateAPI, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";
import {DatePicker, TagInput, Toggle} from "rsuite";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {PostInputSchema} from "./schema";
import {createPost} from "../../services/post";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {BackButton} from "../../components/BackButton/BackButton";
import {TextEdit} from "../../components";

export function PostCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {http} = usePrivateAPI();
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
  } = useForm({
    resolver: yupResolver(PostInputSchema(t)),
    defaultValues: {
      is_show: false,
    },
  });

  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading,
  } = useTermList(1, {limit: 1000, page: 1});

  if (termsError) {
    notifyError(termsError);
    navigate("/posts/manage");
  }

  const create = async (data) => {
    try {
      setIsLoading(true);
      const post = await createPost(data)
      notifySuccess({
        title: "Post created successfully",
        message: `Post "${post.name}" created!`,
      });
      navigate("/posts/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message,
        });
        return;
      }
      notifyError({
        title: "Failed to create post",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isTermsLoading || isLoading) && <CircleLoader/>}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/posts/manage`}> {t("posts_manage_posts")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("users_create_crateUser")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("posts_manage_posts")}/>
        </div>
      </header>
      <section id="#post-create">
        <h2>{t("posts_create_createNewPost")}</h2>
        <Form
          onSubmit={handleSubmit(create)}
          className="mt-4 d-flex  flex-column gap-3"
        >
          <div className="form-row">
            <Form.Group as={Col} md={2} controlId="post_category_id">
              <Form.Label className="mark-required">{t("posts_create_category")}</Form.Label>
              <Form.Control {...register("post_category_id")} as="select" custom>
                <option value={-1}>None</option>
                {termList.terms.map((trm) => (
                  <option value={trm.id}>{trm.name}</option>
                ))}
              </Form.Control>
              {errors.post_category_id?.message && (
                <Form.Text className="text-danger">
                  {errors.post_category_id.message}
                </Form.Text>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="name">
              <Form.Label className="mark-required">{t("posts_create_name")}</Form.Label>
              <Form.Control
                {...register("name")}
                placeholder={t("posts_create_name_plhd")}
                type="text"
              />
              {errors.name?.message && (
                <Form.Text className="text-danger">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <div className="form-row">
            <Form.Group as={Col} controlId="post_date">
              <Form.Label className="mark-required">{t("posts_create_postDate")}</Form.Label>
              <Controller
                control={control}
                name="post_date"
                render={({field}) => (
                  <div>
                    <DatePicker {...field} format="dd-MM-yyyy"/>
                  </div>
                )}
              />
              {errors.post_date?.message && (
                <Form.Text className="text-danger">
                  {errors.post_date.message}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group as={Col} controlId="is_show">
              <Form.Label>{t("posts_create_visibility")}</Form.Label>
              <Controller
                control={control}
                name="is_show"
                render={({field}) => (
                  <div>
                    <Toggle
                      {...field}
                      size="lg"
                      checkedChildren={<span>Public</span>}
                      unCheckedChildren={<span>Private</span>}
                      className="m-auto"
                    />
                  </div>
                )}
              />
              {(errors.is_show?.message || Array.isArray(errors.meta_tag)) && (
                <Form.Text className="text-danger">
                  {errors?.is_show?.message ??
                    errors.is_show.find(Boolean).message}
                </Form.Text>
              )}
            </Form.Group>
          </div>
          <Form.Group controlId="image">
            <Form.Label>{t("posts_create_image")}</Form.Label>
            <div className="w-100">
              <Controller
                control={control}
                name="image"
                render={({field}) => (
                  <div style={{height: "300px"}}>
                    <ImageInput
                      errorMessage={errors.image?.message}
                      onChange={field.onChange}
                      onError={() => {
                        setError("image", {message: "Invalid image file!"})
                      }}
                    />
                  </div>
                )}
              />
            </div>
          </Form.Group>
          <Form.Group controlId="description">
            <Form.Label className="mark-required">{t("posts_create_description")}</Form.Label>
            <Controller
              control={control}
              name="description"
              render={({field}) => <TextEdit {...field} />}
            />
            {errors.description?.message && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>

          <article id="seo" className="mt-4">
            <h3>{t("posts_create_seo")}</h3>

            <div className="mt-2 d-flex gap-2 flex-column">
              <Form.Group controlId="metaTitle">
                <Form.Label >{t("posts_create_metaTitle")}</Form.Label>
                <Form.Control
                  {...register("meta_title")}
                  placeholder={t("posts_create_metaTitle_plhd")}
                  type="text"
                />
                {errors.meta_title?.message && (
                  <Form.Text className="text-danger">
                    {errors.meta_title.message}
                  </Form.Text>
                )}
              </Form.Group>

              <Form.Group controlId="metaTags">
                <Form.Label >{t("posts_create_metaTags")}</Form.Label>
                <Controller
                  control={control}
                  name="meta_tag"
                  render={({field}) => (
                    <TagInput
                      onChange={field.onChange}
                      value={field.value}
                      placeholder={t("posts_create_metaTags_plhd")}
                      style={{borderColor: "#ced4da"}}
                      className="d-block"
                    />
                  )}
                />
                {(errors.meta_tag?.message || Array.isArray(errors.meta_tag)) && (
                  <Form.Text className="text-danger">
                    {errors?.meta_tag?.message ??
                      errors.meta_tag.find(Boolean).message}
                  </Form.Text>
                )}
              </Form.Group>


              <Form.Group controlId="metaDescription">
                <Form.Label >{t("posts_create_metaDescription")}</Form.Label>
                <Form.Control
                  {...register("meta_description")}
                  as="textarea"
                  rows={3}
                  placeholder={t("posts_create_metaDescription_plhd")}
                />
                {errors.meta_description?.message && (
                  <Form.Text className="text-danger">
                    {errors.meta_description.message}
                  </Form.Text>
                )}
              </Form.Group>
            </div>

          </article>


          <div className="d-flex justify-content-end">
            <Button className="px-4" type="submit">
              {t("posts_create_create")}
            </Button>
          </div>
        </Form>
      </section>
    </Layout>
  );
}
