import {useLang} from "../../../hooks";
import {useNotification} from "../../../hooks/useNotification";
import {useSocialSettings} from "../hooks/useSocialSettings";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {SocialSettingsSchema, socialSettingsSchema} from "../schema";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {InputValidationError} from "../../../libs/error";
import {Placeholder} from "rsuite";
import {Button, Form} from "react-bootstrap";

export function SocialSettings({onLanguageEdit}) {
  const {t} = useLang();
  const {notifyError,notifySuccess} = useNotification();
  const {settings, error, isLoading, save} = useSocialSettings();
  const {
    register,
    formState: {errors},
    setValue,
    handleSubmit,
    setError
  } = useForm({
    resolver: yupResolver(SocialSettingsSchema(t))
  });

  useEffect(() => {
    if (settings) {
      setValue("facebook", settings.facebook?.value);
      setValue("instagram", settings.instagram?.value);
      setValue("linkedin", settings.linkedin?.value);
      setValue("youtube", settings.youtube?.value);
    }
  }, [settings]);

  const navigate = useNavigate();
  if (error) {
    notifyError(error);
    navigate("/dashbaord");
  }

  const handleUpdate = async (data) => {
    try {
      await save(data);
      notifySuccess({
        title:"Success",
        message:"Social Settings Updated Successfully"
      })
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update social settings!",
        message: err.message
      });
    }
  };

  return <section id="social-settings" className="my-4">
    <h2 className="mb-3">{t("social_settings_socialSettings")}</h2>
    {isLoading ? <Placeholder.Graph active /> :
      <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit(handleUpdate)}>
        <Form.Group controlId="facebook">
          <Form.Label className="mark-required">{t("social_settings_facebook")}</Form.Label>
          <Form.Control
            {...register("facebook")}
            placeholder={t("social_settings_facebook_plhd")}
            // type="url"
          />
          {errors.facebook?.message && (
            <Form.Text className="text-danger">
              {errors.facebook.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="instagram">
          <Form.Label className="mark-required">{t("social_settings_instagram")}</Form.Label>
          <Form.Control
            {...register("instagram")}
            placeholder={t("social_settings_instagram_plhd")}
            // type="url"
          />
          {errors.instagram?.message && (
            <Form.Text className="text-danger">
              {errors.instagram.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="linkedin">
          <Form.Label className="mark-required">{t("social_settings_linkedin")}</Form.Label>
          <Form.Control
            {...register("linkedin")}
            placeholder={t("social_settings_linkedin_plhd")}
            // type="url"
          />
          {errors.linkedin?.message && (
            <Form.Text className="text-danger">
              {errors.linkedin.message}
            </Form.Text>
          )}
        </Form.Group>

        <Form.Group controlId="youtube">
          <Form.Label className="mark-required">{t("social_settings_youtube")}</Form.Label>
          <Form.Control
            {...register("youtube")}
            placeholder={t("social_settings_youtube_plhd")}
            // type="url"
          />
          {errors.youtube?.message && (
            <Form.Text className="text-danger">
              {errors.youtube.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end gap-2">
          <Button className="px-4" variant="success" type="submit">
            {t("social_settings_save")}
          </Button>
        </div>
      </Form>
    }

  </section>;
}