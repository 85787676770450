import {useCallback, useEffect, useReducer, useState} from "react";
import filterReducers from "../reducers/filterReducer";
import {usePagination} from "./usePagination";
import {usePrivateAPI} from "./usePrivateApi";

export function usePageList(initialFilter, initialPagination) {
  const [pageList, setPageList] = useState({pages: [], total: 0});
  const [filter, filterDispatch] = useReducer(filterReducers, initialFilter ?? {});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const {http} = usePrivateAPI();
  const {pagination, changePagination} = usePagination(initialPagination ?? {
    page: 1,
    limit: 10
  });

  const fetchPages = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await http.get("/page/list", {
        params: {
          page: pagination.page,
          ...filter
        }
      });
      setPageList({
        pages: response.data.data.data,
        total: response.data.data.total
      });

      setIsLoading(false);
    } catch (err) {
      setError({
        title: "Failed to fetch page list",
        message: err.message
      });
    }
  }, [pagination, filter, http]);

  const deletePage = async (id) => {
    await http.delete(`page/delete/${id}`);
    await fetchPages();
  };

  useEffect(() => {
    fetchPages();
  }, [fetchPages]);

  return {
    pageList,
    filterDispatch,
    pagination,
    changePagination,
    deletePage,
    isLoading,
    error,
    filter
  };
}
