import {useState} from "react";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {Link, useNavigate} from "react-router-dom";
import {useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {InputValidationError} from "../../libs/error";

import {yupResolver} from "@hookform/resolvers/yup";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {ContentInputSchema} from "./schema";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {VideoInput} from "../../components/MediaInputs/videoInput";
import {createContent} from "../../services/content";
import {BackButton} from "../../components/BackButton/BackButton";

export function ContentCreatePage() {
  const {t} = useLang();
  const [isLoading, setIsLoading] = useState();
  const navigate = useNavigate();
  const {notifyError, notifySuccess} = useNotification();
  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError
  } = useForm({
    resolver: yupResolver(ContentInputSchema(t))
  });

  const {
    termList,
    error: termsError,
    isLoading: isTermsLoading
  } = useTermList(9, {limit: 1000, page: 1});

  if (termsError) {
    notifyError(termsError);
    navigate("/contents/manage");
  }

  const create = async (data) => {
    try {
      setIsLoading(true);
      const content = await createContent(data);
      notifySuccess({
        title: "Content created successfully",
        message: `Content "${content.title}" created!`
      });

      navigate("/contents/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Input validation failed",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Something went wrong",
        message: "Content creation failed!"
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      {(isTermsLoading || isLoading) && <CircleLoader />}
      <header>
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb className="d-flex align-items-center">
            <li className="breadcrumb-item">
              <Link to={`/contents/manage`}> {t("contents_contents")}</Link>
            </li>
            <Breadcrumb.Item active>
              {t("contents_create_createNewContent")}
            </Breadcrumb.Item>
          </Breadcrumb>
          <BackButton path={-1} text={t("contents_contents")} />
        </div>
      </header>
      <section id="#content-create">
        <h2>{t("contents_create_createNewContent")}</h2>
      </section>
      <Form
        onSubmit={handleSubmit(create)}
        className="mt-4 d-flex  flex-column gap-3"
      >
        <div className="form-row">
          <Form.Group as={Col} md={4} controlId="terms_id">
            <Form.Label className="mark-required">
              {t("contents_create_term")}
            </Form.Label>
            <Form.Control {...register("terms_id")} as="select" custom>
              <option value={-1}>None</option>
              {termList.terms.map((trm) => (
                <option value={trm.id}>{trm.name}</option>
              ))}
            </Form.Control>
            {errors.terms_id?.message && (
              <Form.Text className="text-danger">
                {errors.terms_id.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} className="flex-grow-1" controlId="name">
            <Form.Label className="mark-required">
              {t("contents_create_name")}
            </Form.Label>
            <Form.Control
              {...register("title")}
              placeholder={t("contents_create_name_plhd")}
              type="text"
            />
            {errors.title?.message && (
              <Form.Text className="text-danger">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
        </div>

        <Form.Group controlId="description">
          <Form.Label>
            {t("contents_create_Description")}
          </Form.Label>
          <Form.Control
            {...register("description")}
            placeholder="Content description"
            as="textarea"
            rows={3}
          />
          {errors.description?.message && (
            <Form.Text className="text-danger">
              {errors.description.message}
            </Form.Text>
          )}
        </Form.Group>

        <div className="form-row">
          <Form.Group as={Col} md={12} lg={6} className="mb-4">
            <Form.Label>{t("contents_create_image")}</Form.Label>
            <Controller
              control={control}
              name="image"
              render={({field}) => (
                <div style={{height: "300px"}}>
                  <ImageInput
                    errorMessage={errors.image?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("image", {message: "Invalid image file!"});
                    }}
                  />
                </div>
              )}
            />
          </Form.Group>
          <Form.Group
            as={Col}
            md={12}
            lg={6}
            className="mb-4"
            controlId="video"
          >
            <Form.Label>{t("contents_create_video")}</Form.Label>
            <Controller
              control={control}
              name="video"
              render={({field}) => (
                <div style={{height: "300px"}}>
                  <VideoInput
                    errorMessage={errors.video?.message}
                    onChange={field.onChange}
                    onError={() => {
                      setError("video", {message: "Invalid video file!"});
                    }}
                  />
                </div>
              )}
            />
          </Form.Group>
        </div>
        <Form.Group controlId="Link">
          <Form.Label>
            {t("contents_create_link")}
          </Form.Label>
          <Form.Control
            {...register("link")}
            placeholder={t("contents_create_link_plhd")}
            type="text"
          />
          {errors.link?.message && (
            <Form.Text className="text-danger">{errors.link.message}</Form.Text>
          )}
        </Form.Group>

        <div className="d-flex justify-content-end">
          <Button className="px-4" type="submit">
            {t("contents_create_save")}
          </Button>
        </div>
      </Form>
    </Layout>
  );
}
