import {useEffect, useState} from "react";
import {usePrivateAPI} from "./usePrivateApi";

export function useContent(id) {
  const [content, setContent] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {http} = usePrivateAPI();

  const fetchContent = async () => {
    try {
      setIsLoading(true);
      const response = await http.get(`/master_crud/get/${id}`);
      setContent(response.data.data);
    } catch (err) {
      setError({
        title: "Failed to fetch content!",
        message: err.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  async function editContent(data) {
    try {
      setIsLoading(true);
      if (data.image?.[0]) {
        data.image = data.image?.[0];
      }
      const response = await http.post(`/master_crud/update/${id}`, data);
      setContent(response.data.data);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchContent();
  }, []);

  return {content, isLoading, editContent, error};
}
