import Layout from "../../components/layouts/layout";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {Breadcrumb, Button, Col, Form} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {SelectPicker} from "rsuite";
import {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useComponentCategories, useIdentifiers, useLang} from "../../hooks";
import {yupResolver} from "@hookform/resolvers/yup";
import {componentEditSchema, ComponentInputSchema} from "./schema";
import useComponent from "../../hooks/useComponent";
import {InputValidationError} from "../../libs/error";
import {getUrlFromStoragePath} from "../../configs";
import {BackButton} from "../../components/BackButton/BackButton";
import {ImageInput} from "../../components/MediaInputs/imageInput";
import {useNotification} from "../../hooks/useNotification";
import {TextEdit} from "../../components";

export function ComponentEditPage() {
  const {t} = useLang();
  const {id} = useParams();

  const {notifyError, notifySuccess} = useNotification();
  const {component, isLoading, editComponent} = useComponent(id);

  const {
    register,
    handleSubmit,
    control,
    formState: {errors},
    setError,
    resetField,
    watch,
    reset,
    setValue
  } = useForm({resolver: yupResolver(ComponentInputSchema(t))});

  const typeId = watch("type_id");
  const {categories, isLoading: isCategoryLoading, error: catErr} = useComponentCategories();
  const {identifiers, isLoading: isIdentifiersLoading, idErr} = useIdentifiers(typeId ? Number(typeId) : undefined);

  if (catErr || idErr) {
    notifyError(catErr ?? idErr, "/error");
  }
  const handleEdit = async (data) => {
    try {
      await editComponent(data);
      notifySuccess({
        title: `Component "${component.title}" updated successfully`
      }, "/components/manage");
    } catch (err) {
      if (err instanceof InputValidationError) {
        err.setRHFError(setError);
        notifyError({
          title: "Invalid input data!",
          message: err.message
        });
        return;
      }
      notifyError({
        title: "Failed to update component!",
        message: err.message
      });
    }
  };

  let componentName = watch("title");


  useEffect(() => {
    if (component) {
      const {image1, image2, image3, ...prev}  = component
      reset(prev)
    }
  }, [component]);

  return (
    <>
      <Layout>
        {isLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between align-items-center">
            <Breadcrumb className="d-flex align-items-center">
              <li className="breadcrumb-item">
                <Link to={`/components/manage`}> {t("components_manage_newComponent")}</Link>
              </li>
              <Breadcrumb.Item active>
                {componentName} {t("components_edit_editComponent")}
              </Breadcrumb.Item>
            </Breadcrumb>
            <BackButton path={-1} text={t("users_page_title")} />
          </div>
        </header>
        <section id="#taxonomy-create">
          <h2>{t("components_edit_editComponent")}/ {componentName}</h2>
        </section>
        <Form onSubmit={handleSubmit(handleEdit)} className="container-fluid mt-4">
          <div className="form-row gap-3 gap-md-0">
            <Form.Group as={Col} md={6} controlId="type_id">
              <Form.Label
                className="mark-required">{t("components_create_selectComponentType")}</Form.Label>
              <Controller name="type_id" control={control} render={({field: {onChange, value}}) => (
                <SelectPicker onChange={(value) => {
                  onChange(value);
                  resetField("identifier_id");
                }} value={value} data={categories} block
                              searchable={false} />
              )} />
              {errors.type_id?.message && (
                <Form.Text className="text-danger">{errors.type_id.message}</Form.Text>)}
            </Form.Group>

            <Form.Group as={Col} md={6} controlId="identifier_id">
              <Form.Label
                className="mark-required">{t("components_create_selectIdentifier")}</Form.Label>
              <Controller name="identifier_id" control={control}
                          render={({field: {onChange, value}}) => (
                            <SelectPicker value={value} onChange={onChange} data={identifiers}
                                          block loading={isIdentifiersLoading} />
                          )} />
              {errors.identifier_id?.message && (
                <Form.Text className="text-danger">{errors.identifier_id.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="form-row gap-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="title">
              <Form.Label className="mark-required">{t("components_create_title")}</Form.Label>
              <Form.Control {...register("title")} placeholder={t("components_create_title_plhd")}
                            type="text" />
              {errors.title?.message && (
                <Form.Text className="text-danger">{errors.title.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="component_tag">
              <Form.Label className="mark-required">{t("tag")}</Form.Label>
              <Form.Control {...register("component_tag")} placeholder={t("tag_phld")}
                            type="text" />
              {errors.component_tag?.message && (
                <Form.Text className="text-danger">{errors.component_tag.message}</Form.Text>)}
            </Form.Group>
          </div>
          <Form.Group className="flex-grow-1" controlId="name">
            <Form.Label>{t("subtitle")}</Form.Label>
            <Form.Control {...register("subTitle")} placeholder={t("subtitle_phld")}
                          type="text" />
            {errors.subTitle?.message && (
              <Form.Text className="text-danger">{errors.subTitle.message}</Form.Text>)}
          </Form.Group>
          <div className="form-row gap-3 gap-md-0">
            <Form.Group as={Col} md={4} controlId="image1">
              <Form.Label>{t("image")} {t("1")}</Form.Label>
              <div className="w-100">
                <Controller
                  control={control}
                  name="image1"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <ImageInput
                        {...(component?.image1 && {previousImage: getUrlFromStoragePath(component.image1)})}
                        errorMessage={errors.image1?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("image1", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="image2">
              <Form.Label>{t("image")} {t("2")}</Form.Label>
              <div className="w-100">
                <Controller
                  control={control}
                  name="image2"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <ImageInput
                        {...(component?.image2 && {previousImage: getUrlFromStoragePath(component.image2)})}
                        errorMessage={errors.image2?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("image2", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} md={4} controlId="image3">
              <Form.Label>{t("image")} {t("3")}</Form.Label>
              <div className="w-100">
                <Controller
                  control={control}
                  name="image3"
                  render={({field}) => (
                    <div style={{height: "300px"}}>
                      <ImageInput
                        {...(component?.image3 && {previousImage: getUrlFromStoragePath(component.image3)})}
                        errorMessage={errors.image3?.message}
                        onChange={field.onChange}
                        onError={() => {
                          setError("image3", {message: "Invalid image file!"});
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            </Form.Group>
          </div>




          <Form.Group controlId="description1">
            <Form.Label>{t("description")} {t("1")}</Form.Label>
            <Controller
              control={control}
              name="description1"
              render={({field}) => <TextEdit defaultValue={component?.description1} {...field} />}
            />
            {errors.description1?.message && (
              <Form.Text className="text-danger">
                {errors.description1.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group controlId="description2">
            <Form.Label>{t("description")} {t("2")}</Form.Label>
            <Controller
              control={control}
              name="description2"
              render={({field}) => <TextEdit defaultValue={component?.description2} {...field} />}
            />
            {errors.description2?.message && (
              <Form.Text className="text-danger">
                {errors.description2.message}
              </Form.Text>
            )}
          </Form.Group>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link1">
              <Form.Label>{t("link")} {t("1")}</Form.Label>
              <Form.Control {...register("link1")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link1?.message && (
                <Form.Text className="text-danger">{errors.link1.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link2">
              <Form.Label>{t("link")} {t("2")}</Form.Label>
              <Form.Control {...register("link2")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link2?.message && (
                <Form.Text className="text-danger">{errors.link2.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="form-row gap-3 md-2 gap-md-0">
            <Form.Group as={Col} md={6} className="flex-grow-1" controlId="link3">
              <Form.Label>{t("link")} {t("3")}</Form.Label>
              <Form.Control {...register("link3")} placeholder={t("link_phld")}
                            type="text" />
              {errors.link3?.message && (
                <Form.Text className="text-danger">{errors.link3.message}</Form.Text>)}
            </Form.Group>
            <Form.Group as={Col} md={6} controlId="youTubeID">
              <Form.Label>{t("components_create_youtubeID")}</Form.Label>
              <Form.Control {...register("youTubeID")}
                            placeholder={t("components_create_youtubeID_plhd")} type="text" />
              {errors.youTubeID?.message && (
                <Form.Text className="text-danger">{errors.youTubeID.message}</Form.Text>)}
            </Form.Group>
          </div>
          <div className="row mt-2">
            <div className="d-flex justify-content-end">
              <Button className="px-4 mr-2" type="submit">
                {t("components_edit_update")}
              </Button>
              <Link className="btn btn-danger" to="/components/manage">
                {t("pages_edit_cancel")}
              </Link>
            </div>
          </div>
        </Form>
      </Layout>
    </>
  );
}